import { CloseCircleTwoTone } from "@ant-design/icons";
import { Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch, RootState } from "../../rematch/store";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";

const AuthModule = () => {
  const dispatch = useDispatch<Dispatch>();
  const visible = useSelector(
    (state: RootState) => state.auth.authModalVisible
  );
  const navigate = useNavigate();

  const authPage = useSelector((state: RootState) => state.auth.authPage);
  const params = new URLSearchParams(window.location.search);
  const page = params.get("page");

  useEffect(() => {
    if (page) {
      dispatch.auth.setAuthModalVisible(true);
      params.delete("page");
      dispatch.auth.changeAuthPage(page as any);
      navigate(`${window.location.pathname}?${params.toString()}`, {
        replace: true,
      });
    }
  }, [page]);

  return (
    <Modal
      centered
      closeIcon={<CloseCircleTwoTone />}
      width={320}
      footer={null}
      visible={visible}
      onCancel={() => {
        dispatch.auth.setAuthModalVisible(false);
      }}
    >
      {authPage === "login" && <Login />}
      {authPage === "register" && <Register />}
      {authPage === "forgot-password" && <ForgotPassword />}
      {authPage === "reset-password" && <ResetPassword />}
    </Modal>
  );
};

export default AuthModule;
