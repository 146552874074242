import React from "react";
import { Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../rematch/store";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as MyLogo } from "../../../assets/imgs/logo_hoz.svg";
import useLoading from "../../../hooks/useLoading";

const ResetPassword = () => {
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    delete values.newPassword;
    dispatch.auth.resetPassword({ ...values, token });
  };

  const user = useSelector((state: RootState) => state.auth.user);
  const { loading, error, success, finished } = useLoading(
    (state: RootState) => state.loading.effects.auth.resetPassword
  );
  if (finished && success) {
    const params = new URLSearchParams(window.location.search);

    const currentPath = window.location.pathname;
    params.append("isResetPwdSuccess", "true");

    dispatch.auth.changeAuthPage("login");
    navigate(`${currentPath}?${params.toString()}`);
  }
  if (user) {
    dispatch.auth.setAuthModalVisible(false);
    dispatch.auth.changeAuthPage("login");
  }
  return (
    <Form style={{ marginTop: 32 }} onFinish={onFinish}>
      <div className="flex-center">
        <h1>Đặt lại mật khẩu</h1>
      </div>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "Vui lòng không để trống mật khẩu" },
        ]}
      >
        <Input
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Mật khẩu"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 4 }}
        name="newPassword"
        rules={[
          { required: true, message: "Vui lòng không để trống mật khẩu" },
        ]}
      >
        <Input
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Mật khẩu mới"
        />
      </Form.Item>
      {error && (
        <span style={{ color: "red", fontSize: 12 }}>
          Không thể đặt lại mật khẩu
        </span>
      )}
      <Form.Item>
        <Button
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button mt-2"
          style={{ width: "100%" }}
        >
          Đặt lại mật khẩu
        </Button>
      </Form.Item>
      Hoặc{" "}
      <Button
        className="px-0"
        type="link"
        onClick={() => dispatch.auth.changeAuthPage("login")}
      >
        đăng nhập
      </Button>
    </Form>
  );
};

export default ResetPassword;
