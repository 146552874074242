/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../rematch/store";

import { SaltusStageFormat } from "./Saltus/defines";
import { PoluxStageFormat } from "./Pollux/defines";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./Stage.scss";
import { BlockProps, StageProps, RowsProps, ColProps } from "./types";
import { MapEventEmitter } from "../../../sockets/core/event";
import { getBgColor, seatColors } from "./helper";
import { Seat, SeatStatus, Zone } from "../../../models/type/seat";
import { TicketPlan } from "../../../models/type/event";
import _ from "lodash";
import { DiscountType } from "../../../models/discount";

export enum TicketType {
  STANDARD = "STANDARD",
  COMBO = "COMBO",
}

const Stage = (props: any) => {
  const { bookedSeatIds = [], event, discount } = props;
  const [stageFormat, setStageFormat] = useState<StageProps | null>(null);
  const dispatch = useDispatch<Dispatch>();

  const priceMap = useSelector((state: RootState) => state.booking.priceMap);
  const seatInfoMap = useSelector(
    (state: RootState) => state.booking.seatInfoMap
  );

  const selectedZones = useSelector(
    (state: RootState) => state.booking.selectedZones
  );

  const dimension = useSelector((state: RootState) => state.dimension);
  const user = useSelector((state: RootState) => state.auth.user);
  const selectedSeats = useSelector(
    (state: RootState) => state.booking.selectedSeats
  );
  const selectedSeatMap = useSelector(
    (state: RootState) => state.booking.selectedSeatMap
  );
  const selectedZoneMap = useSelector(
    (state: RootState) => state.booking.selectedZoneMap
  );

  const isMobile = useSelector((state: RootState) => state.dimension.isMobile);

  const setSelectedSeats = (selectedSeats: any) => {
    dispatch.booking.updateBookingState({ selectedSeats });
  };

  useEffect(() => {
    switch (event?.stageId) {
      case "10000":
        setStageFormat(SaltusStageFormat);
        break;
      case "10001":
        setStageFormat(PoluxStageFormat);
        break;
      default:
        setStageFormat(SaltusStageFormat);

        break;
    }
  }, [event]);

  useEffect(() => {
    if (event.ticketPlan !== TicketPlan.SEAT) {
      return;
    }
    let totalPrice = 0;
    let subPrice = 0;
    Array.from(Object.values(selectedSeatMap)).forEach((acc: any) => {
      totalPrice = totalPrice + acc.selectedPrice * (acc.quantity || 1);
    });

    subPrice = totalPrice;
    let discountAmount = 0;
    if (discount) {
      if (discount?.discountType === DiscountType.AMOUNT) {
        discountAmount = discount.amountDiscount;
      } else {
        discountAmount = (totalPrice * discount.percentageDiscount) / 100;
      }
      totalPrice = Math.round((totalPrice - discountAmount) * 100) / 100;
      totalPrice = totalPrice > 0 ? totalPrice : 0;
    }
    dispatch.booking.updateBookingDetail({
      totalPrice,
      subPrice,
      eventId: event?.id,
      seatInfo: Array.from(Object.values(selectedSeatMap)),
    });
    return () => {};
  }, [event, selectedSeatMap, dispatch.booking, discount]);

  useEffect(() => {
    if (event.ticketPlan !== TicketPlan.GROUP) {
      return;
    }
    let totalPrice = 0;
    let subPrice = 0;
    Array.from(Object.values(selectedZoneMap)).forEach((acc: any) => {
      totalPrice = totalPrice + acc.selectedPrice * acc.quantity;
    });
    subPrice = totalPrice;
    let discountAmount = 0;
    if (!totalPrice) {
      return;
    }
    if (discount) {
      if (discount?.discountType === DiscountType.AMOUNT) {
        discountAmount = discount.amountDiscount;
      } else {
        discountAmount = (totalPrice * discount.percentageDiscount) / 100;
      }
      totalPrice = Math.round((totalPrice - discountAmount) * 100) / 100;
      totalPrice = totalPrice > 0 ? totalPrice : 0;
    }
    dispatch.booking.updateBookingDetail({
      totalPrice,
      subPrice,
      eventId: event?.id,
      blockInfo: Array.from(Object.values(selectedZoneMap)),
    });
    return () => {};
  }, [event, selectedZoneMap, dispatch.booking, discount]);

  const bookingUsers = useSelector(
    (state: RootState) => state.booking.bookingUsers
  );

  const unavailableSeatIds = useSelector(
    (state: RootState) => state.booking.unavailableSeatIds
  );

  const onSeatClick = (seat: Seat) => {
    const isSelected = !!selectedSeats?.find(
      (e: Seat) => e.seatId === seat.seatId
    );
    if (!user) {
      dispatch.auth.setAuthModalVisible(true);
      return;
    }

    let tempSelectSeats: any = [...selectedSeats];
    if (isSelected) {
      tempSelectSeats = selectedSeats.filter(
        (_selectedSeat: Seat) => _selectedSeat.seatId !== seat.seatId
      );
    } else {
      tempSelectSeats = [...selectedSeats, seat];
    }

    setSelectedSeats(tempSelectSeats);

    MapEventEmitter.selectSeats(event?.id!, tempSelectSeats);
  };
  const onZoneClick = (zone: Zone) => {
    if (!user) {
      dispatch.auth.setAuthModalVisible(true);
      return;
    }

    let tempSelectZones: any = selectedZones ? [...selectedZones] : [];
    const isSelected = !!tempSelectZones.find(
      (_zone: Zone) => _zone.block === zone.block
    );
    if (isSelected) {
      tempSelectZones = selectedZones?.filter(
        (_selectedZone: Zone) => _selectedZone.block !== zone.block
      );
    } else {
      tempSelectZones = [...tempSelectZones, zone];
    }
    dispatch.booking.updateBookingState({
      selectedZones: tempSelectZones,
    });
  };

  useEffect(() => {
    const tempMap: any = {};
    selectedSeats.forEach((seat: Seat) => {
      const seatPrice = priceMap[seat.seatClass];
      tempMap[seat.seatId] = {
        ...seatPrice,
        selectedPrice: seatPrice?.comboPrice || seatPrice?.price || 0,
        selectedPriceType: seatPrice?.comboPrice
          ? TicketType.COMBO
          : TicketType.STANDARD,
        ticketType: seatPrice?.comboPrice
          ? TicketType.COMBO
          : TicketType.STANDARD,
        seat: seat.seatId,
        ...seat,
        seatId: seat.seatId,
        price: seatPrice?.comboPrice || seatPrice?.price || 0,
        seatClass: seat.seatClass,
        quantity:
          selectedSeatMap[seat.seatId]?.quantity || seat.seatClass === "SUN"
            ? 2
            : 1,
      };
    });
    updateSelectedSeatMap(tempMap);
  }, [selectedSeats]);

  const updateSelectedSeatMap = (selectedSeatMap: any) => {
    dispatch.booking.updateBookingState({
      selectedSeatMap,
    });
  };

  // useEffect(() => {
  //   const tempMap: any = {};
  //   selectedZones.forEach((zone: Zone) => {
  //     const zonePrice = priceMap[zone.seatClass];
  //     tempMap[zone.block] = {
  //       ...zonePrice,
  //       block: zone.block,
  //       quantity: 1,
  //       seatClass: zone.seatClass,
  //       ticketType: TicketType.STANDARD,
  //     };
  //   });
  //   updateSelectedZoneMap(tempMap);
  // }, [selectedZones]);

  // const updateSelectedZoneMap = (selectedZoneMap: any) => {
  //   dispatch.booking.updateBookingState({
  //     selectedZoneMap,
  //   });
  // };

  const [stageWidth, setWidth] = useState(0);
  const [scale, setScale] = useState(0);

  const panZoomRef = useRef<any>(null);
  useEffect(() => {
    const stage = document.querySelector(".stage");

    if (stage && stageFormat) {
      const resizeObserver = new ResizeObserver((entries) => {
        const width = entries[0].contentRect.width;
        setWidth(width);

        const scale =
          Math.round(
            (width / stageFormat.options.width + Number.EPSILON) * 100
          ) / 100;
        if (event?.ticketPlan === TicketPlan.GROUP) {
          panZoomRef?.current?.centerView(1);
          return;
        } else {
          setScale(scale);
          panZoomRef?.current?.centerView(scale);
        }
      });

      resizeObserver.observe(stage);
    }
  }, [document.querySelector(".stage"), stageFormat]);
  if (!stageFormat) {
    return <></>;
  }
  let tempSeatMap: any = {};

  const renderSeatRows = (block: BlockProps) => {
    if (!block.seatRows) {
      return <></>;
    }
    const data = block.seatRows.map((seatRow, index) => {
      let seats: any = [];

      for (let i = seatRow.from; i <= seatRow.to; i++) {
        seats.push(i);
      }
      if (seatRow.render) {
        return seatRow.render({
          selectedSeats,
          unavailableSeatIds,
          setSelectedSeats,
          bookedSeatIds,
          onSeatClick,
          priceMap,
          onZoneClick,
        });
      }
      const seatClass = seatRow.seatClass;
      const renderSeats = () => {
        const result = seats.map((position: number) => {
          const seatId = `${seatRow.name}${position}`;

          const seat: Seat = {
            block: block.name,
            row: seatRow.name,
            position: position,
            seatId,
            seatClass: seatClass,
            status: SeatStatus.AVAILABLE,
            price: priceMap[seatClass],
          };

          tempSeatMap[seat.seatId] = seat;

          const isSelected = !!selectedSeats?.find(
            (e: Seat) => e.seatId === seat.seatId
          );
          const isBooked = bookedSeatIds.includes(seatId);
          const isUnavailable = unavailableSeatIds.includes(seatId);
          const isDisabled = isBooked || isUnavailable;

          return (
            <Button
              disabled={isDisabled}
              style={{
                backgroundColor: getBgColor({
                  isSelected,
                  isDisabled,
                  isUnavailable,
                  color: seatRow?.color,
                }),
                ...block?.options?.buttonStyle,
                ...seatRow?.buttonStyle,
              }}
              className={`${isSelected ? "square-active" : "square"}`}
              onClick={() => onSeatClick(seat)}
            >
              {position}
            </Button>
          );
        });

        return result;
      };

      return (
        <div
          className="seat-row"
          key={index}
          style={{
            marginLeft: !seatRow?.offset ? 0 : (seatRow?.offset - 1) * 22,
            marginBottom: 12,
            ...seatRow.style,
          }}
        >
          {block?.options?.labelPosition === "start" && (
            <span
              className="text flex-center"
              style={{
                textAlign: "left",
                ...block?.options?.labelStyle,
                ...seatRow?.labelStyle,
                width: 18,
                height: 18,
              }}
            >
              {seatRow.name}
            </span>
          )}
          {renderSeats()}
          {block?.options?.labelPosition !== "start" && (
            <span
              className="text flex-center"
              style={{
                flex: "auto",
                textAlign: "right",
                ...block?.options?.labelStyle,
                ...seatRow?.labelStyle,
              }}
            >
              {seatRow.name}
            </span>
          )}
        </div>
      );
    });
    return { components: data, seatMap: tempSeatMap };
  };

  const renderStage = () => {
    if (event.ticketPlan === TicketPlan.GROUP) {
      return (
        <img
          alt="event"
          style={{ width: "100%", height: "100%" }}
          src={event?.seatPlanImg}
        ></img>
      );
    }
    if (!stageFormat) {
      return <></>;
    }

    const renderRows = () => {
      const rows = stageFormat.rows.map((row: RowsProps, index: number) => {
        return (
          <div
            className="stage-row flex-center"
            key={index}
            style={{ ...row.style }}
          >
            {renderCols(row.cols)}
          </div>
        );
      });

      return rows;
    };

    const renderCols = (cols: ColProps[]) => {
      const components = cols.map((col, index) => {
        return renderCol(col);
      });

      return components;
    };

    const renderCol = (col: ColProps) => {
      const result: any = col.data
        ? renderSeatRows(col.data)
        : {
            components: [] as any,
            seatMap: tempSeatMap,
          };

      return (
        <div className="stage-col" style={{ ...col.style }}>
          {col?.type !== "custom" ? (
            <div className="seat-container" style={{ ...col.innerStyle }}>
              {col.data && result.components}
            </div>
          ) : (
            col?.render({ onZoneClick, selectedZones, seatInfoMap })
          )}
        </div>
      );
    };

    return <div style={{ position: "relative" }}> {renderRows()}</div>;
  };
  const renderSeatType = () => {
    if (event?.ticketPlan === TicketPlan.GROUP) {
      return <></>;
    }
    return (
      <Row
        className="seat-type-container"
        gutter={[16, 16]}
        justify={isMobile ? "start" : "center"}
      >
        {_.uniqBy(
          event[
            event?.ticketPlan === TicketPlan.SEAT ? "seatTypes" : "groupTypes"
          ],
          "seatClass"
        )?.map((seatType: any, index: number) => {
          return (
            <Col xs={12} sm={12} md={4} key={index} className="seat-type-col">
              <div style={{ display: "flex" }}>
                <div
                  className={`seat-code ${seatType?.title?.toLowerCase()}`}
                  style={{
                    backgroundColor: seatColors[seatType?.seatClass],
                  }}
                ></div>
                <b className="text">{seatType?.title}</b>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <Row style={{ width: "100%", maxWidth: 1360 }} className="flex-center">
      <div
        className="flex-center"
        style={{ flexDirection: "column", width: "100%" }}
      >
        <h1 className="section-title flex-center mt-2">Sơ đồ chỗ ngồi</h1>
        <span className="text flex-center">
          {bookingUsers?.length
            ? `Có ${bookingUsers?.length} người xem đang chọn đặt chỗ cùng bạn`
            : ""}
        </span>
      </div>

      <Col span={24} className={dimension.isMobile ? "p-0.25" : "p-1"}>
        <div className="stage">
          {stageWidth && (
            <TransformWrapper
              ref={panZoomRef}
              minScale={event?.ticketPlan === TicketPlan.GROUP ? 1 : scale}
              centerOnInit
              initialScale={event?.ticketPlan === TicketPlan.GROUP ? 1 : 0}
            >
              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height:
                    event?.ticketPlan === TicketPlan.GROUP
                      ? "100%"
                      : stageFormat.options.height * scale,
                }}
              >
                {renderStage()}
              </TransformComponent>
            </TransformWrapper>
          )}
        </div>
        {renderSeatType()}
      </Col>
    </Row>
  );
};

export default Stage;
