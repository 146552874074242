export type Seat = {
  block: string;
  row: string;
  position: number;
  price: number;
  status: SeatStatus;
  seatId: string;
  seatClass: string;
  userId?: string;
  title?: string;
};

export type Zone = {
  block: string;
  price: number;
  status: SeatStatus;
  seatId: string;
  seatClass: string;
  userId?: string;
  quantity?: number;
  title?: string;
};

export enum SeatStatus {
  AVAILABLE = "AVAILABLE",
  BOOKED = "BOOKED",
  RESERVED = "RESERVED",
  LOCKED = "LOCKED",
  UNAVAILABLE = "UNAVAILABLE",
}
