import { Socket } from "socket.io-client";
import { store } from "../../rematch/store";
import { BookingEvent } from "../events/booking-event";

export const initBookingHandlers = (socket: Socket) => {
  socket.on(BookingEvent.BOOKING_STATUS_CHANGED, (data) => {
    store.dispatch.booking.createBookingSuccess({
      ...data,
      id: data?.bookingId,
    });
  });
  socket.on(BookingEvent.INIT, (data) => {
    store.dispatch.booking.createBookingSuccess(data);
  });
  socket.on(BookingEvent.REFRESH_MAP, (data) => {
    store.dispatch.event.refreshEvent({});
  });
};
