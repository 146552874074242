import { createModel } from "@rematch/core";
import { RootModel } from ".";
import {
  checkEmail,
  forgotPassword,
  getMe,
  login,
  register,
  resetPassword,
  socialLogin,
} from "../apis/auth";

export type AuthPage =
  | "login"
  | "register"
  | "forgot-password"
  | "reset-password";

type AuthState = {
  user: any;
  authModalVisible: boolean;
  tokens: {
    access: {
      token: string;
      expires: string;
    };
    refresh: {
      token: string;
      expires: string;
    };
  } | null;
  authPage: AuthPage;
};

export const auth = createModel<RootModel>()({
  state: {
    user: null,
    tokens: null,
    authModalVisible: false,
    authPage: "register",
  } as AuthState,
  reducers: {
    changeAuthPage: (state: AuthState, payload: AuthPage) => {
      return {
        ...state,
        authPage: payload,
      };
    },
    setAuthModalVisible: (state: AuthState, payload: any) => {
      return {
        ...state,
        authModalVisible: payload,
      };
    },
    loginSuccess(state, payload: any) {
      return {
        ...state,
        user: payload.user,
        tokens: payload.tokens,
        authModalVisible: false,
      };
    },
    getMeSuccess(state, payload: any) {
      return { ...state, user: payload };
    },
    logOut(state) {
      return { ...state, user: null, tokens: null };
    },
  },
  effects: (dispatch) => ({
    async login(payload: any, state) {
      const res = await login(payload);
      if (res) {
        dispatch.auth.loginSuccess(res.data?.result);
        dispatch.auth.setAuthModalVisible(false);
      }
    },
    async socialLogin(payload: any, state) {
      const res = await socialLogin(payload);
      if (res) {
        dispatch.auth.loginSuccess(res.data?.result);
      }
    },
    async register(payload: any, state) {
      const cb = payload?.cb;
      delete payload.cb;
      try {
        const res = await register(payload);
        if (res?.data?.result) {
          cb && cb(res?.data?.result);
        } else {
          cb && cb(null);
        }
      } catch (error) {
        cb && cb(null);
      }
    },
    async getMe() {
      const res = await getMe();
      if (res) {
        dispatch.auth.getMeSuccess(res.data?.result);
      }
    },
    async checkEmail(payload: any) {
      const cb = payload?.cb;
      delete payload.cb;
      const res = await checkEmail(payload);
      if (res?.data?.result) {
        cb && cb(res?.data?.result);
      } else {
        cb && cb(null);
      }
    },
    async forgotPassword(payload: any) {
      await forgotPassword(payload);
    },
    async resetPassword(payload: any) {
      await resetPassword(payload);
    },
  }),
});
