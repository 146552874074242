import { Button, Col, Dropdown, Layout, Menu, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../components/Button/Button";
import AuthModule from "../pages/Auth";
import { Dispatch, RootState } from "../rematch/store";

import "./Layout.scss";
const { Content } = Layout;
const MainLayout: React.FC = (props) => {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
      dispatch.dimension.updateDimension({
        screenWidth,
        screenHeight,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch.dimension]);

  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const isDesktop = useSelector(
    (state: RootState) => state.dimension.isDesktop
  );

  const menu = (
    <Menu>
      <Menu.Item key={"logout"} onClick={() => dispatch.auth.logOut()}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  );

  // const renderLogo = () => {
  //   const params = new URLSearchParams(window.location.search);
  //   const programId = params.get("program");
  //   const program = Program[programId || ""];
  //   if (!program) {
  //     return <></>;
  //   }
  //   return (
  //     <>
  //       ✕ <img alt="logo" src={program.partnerLogo}></img>
  //     </>
  //   );
  // };

  const isHomePage = window.location.pathname === "/";

  return (
    <>
      <AuthModule />
      <Layout className="site-layout-background">
        <Row className="logo-container">
          {!isHomePage && (
            <Col
              md={8}
              sm={24}
              xs={24}
              className="col first-logo text"
              onClick={() => navigate("/")}
            >
              <img alt="logo" src="/the_bros_dark.png"></img>
              {/* {renderLogo()} */}
            </Col>
          )}
          <></>
          <Col className="col" md={8} sm={24} xs={24}>
            {user ? (
              <Dropdown overlay={menu} placement="bottom" trigger={["click"]}>
                <Button
                  type="text"
                  shape="round"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.03)" }}
                >
                  <span className="text"> Xin chào, {user?.name} </span>
                </Button>
              </Dropdown>
            ) : (
              <CommonButton
                onClick={() => dispatch.auth.setAuthModalVisible(true)}
              >
                Đăng nhập
              </CommonButton>
            )}
          </Col>
        </Row>

        <Content
          style={{
            margin: 0,
            zIndex: 2,
          }}
        >
          <div
            className="overflow-hidden"
            style={{
              height: "100vh",
              position: "fixed",
              width: "100%",
              zIndex: -1,
              top: 1,
            }}
          >
            <img
              src="/hero-glow.svg"
              alt=""
              className="js-warp-hide position-absolute overflow-hidden home-hero-glow events-none"
            />
            <div className="signup-space">
              <div className="signup-stars"></div>
              <div className="signup-stars"></div>
              <div className="signup-stars"></div>
              <div className="signup-stars"></div>
              <div className="signup-stars"></div>
            </div>
          </div>
          {props.children}
        </Content>
      </Layout>
    </>
  );
};

export default MainLayout;
