import { createModel } from "@rematch/core";
import { RootModel } from ".";
const BREAKPOINTS = { tablet: 768, desktop: 1280 };
type DimensionState = {
  screenWidth: number;
  screenHeight: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export const dimension = createModel<RootModel>()({
  state: {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  } as DimensionState,
  reducers: {
    updateDimension(state, payload: any) {
      return {
        ...state,
        screenHeight: payload.screenHeight || 0,
        screenWidth: payload.screenWidth || 0,
        isDesktop: payload.screenWidth >= BREAKPOINTS.desktop,
        isTablet:
          payload.screenWidth >= BREAKPOINTS.tablet &&
          payload.screenWidth < BREAKPOINTS.desktop,
        isMobile: payload.screenWidth < BREAKPOINTS.tablet,
      };
    },
  },
});
