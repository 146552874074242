import React from "react";

import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
} from "firebase/auth";
import { Button, Col, message, Row } from "antd";
import { Dispatch, RootState } from "../../rematch/store";
import { useDispatch } from "react-redux";
import useLoading from "../../hooks/useLoading";
import { ReactComponent as Google } from "../../assets/imgs/icons8-google.svg";
import { ReactComponent as Facebook } from "../../assets/imgs/icons8-facebook.svg";

const SocialLogin = () => {
  const ggProvider = new GoogleAuthProvider();
  const faProvider = new FacebookAuthProvider();
  const auth = getAuth();
  const dispatch = useDispatch<Dispatch>();

  const loginLoadingState = useLoading(
    (state: RootState) => state.loading.effects.auth.socialLogin
  );
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Button
          size="large"
          loading={loginLoadingState.loading}
          className="w-100 flex-center"
          onClick={() =>
            signInWithPopup(auth, ggProvider)
              .then((result: any) => {
                const token = result?.user?.accessToken;
                dispatch.auth.socialLogin({ token });
              })
              .catch((error) => {
                if (
                  error.code === "auth/account-exists-with-different-credential"
                ) {
                  message.error("Email đã được đăng ký bởi tài khoản khác");
                } else {
                  message.error("Không thể sử dụng tài khoản này");
                }
              })
          }
        >
          <Google
            style={{ fontSize: 24, marginRight: 5 }}
            width={22}
            height={22}
          />
          <span>Đăng nhập bằng Google</span>
        </Button>
      </Col>
      <Col span={24}>
        <Button
          size="large"
          className="w-100 flex-center"
          loading={loginLoadingState.loading}
          onClick={() =>
            signInWithPopup(auth, faProvider)
              .catch((error) => {
                if (
                  error.code === "auth/account-exists-with-different-credential"
                ) {
                  message.error("Email đã được đăng ký bởi tài khoản khác");
                } else {
                  message.error("Không thể sử dụng tài khoản này");
                }
              })
              .then((result: any) => {
                const token = result?.user?.accessToken;

                dispatch.auth.socialLogin({ token });
              })
          }
        >
          <Facebook
            style={{ fontSize: 24, marginRight: 5 }}
            width={24}
            height={24}
          />
          <span>Đăng nhập bằng Facebook</span>
        </Button>
      </Col>
    </Row>
  );
};

export default SocialLogin;
