import { Divider, PageHeader } from "antd";
import React from "react";

const Term = () => {
  return (
    <div style={{ color: "white" }}>
      <div className="static-page">
        <PageHeader
          className="site-page-header"
          title={
            <h3 style={{ fontWeight: 600, color: "white" }}>
              Điều khoản dịch vụ
            </h3>
          }
        />
        <Divider style={{ background: "white", margin: 0 }} />
        <div style={{ padding: 30 }}>
          <h3 style={{ fontWeight: 600, color: "white" }}>
            Điều khoản dịch vụ
          </h3>
          <br />
          <strong>1. Giới thiệu</strong>
          <br />
          <br />
          <div>
            1.1. Chào mừng bạn đến với website TheBros - nền tảng nền tảng tập
            hợp tất cả các dự án cần kêu gọi tài trợ đầu tiên tại Việt Nam.
            Trước khi sử dụng website hoặc tạo tài khoản The Bros, vui lòng đọc
            kỹ các Điều Khoản Dịch Vụ dưới đây để hiểu rõ quyền lợi và nghĩa vụ
            hợp pháp của mình đối với Công ty Cổ phần The A List Việt Nam (sau
            đây được gọi chung là “The Bros”, “chúng tôi”). “Dịch vụ” chúng tôi
            cung cấp sẵn có bao gồm (a) Trang The Bros, (b) các dịch vụ được
            cung cấp bởi Trang The Bros, và (c) tất cả các thông tin, đường dẫn,
            tính năng, dữ liệu, văn bản, hình ảnh, biểu đồ, âm nhạc, âm thanh,
            video (bao gồm cả các đoạn video được đăng tải trực tiếp theo thời
            gian thực (livestream)), tin nhắn, tags, nội dung, chương trình,
            phần mềm, ứng dụng dịch vụ (bao gồm bất kỳ ứng dụng dịch vụ di động
            nào) hoặc các tài liệu khác có sẵn trên Trang TheBros hoặc các dịch
            vụ liên quan đến Trang TheBros (“Nội Dung”). Bất kỳ tính năng mới
            nào được bổ sung hoặc mở rộng đối với Dịch Vụ đều thuộc phạm vi điều
            chỉnh của Điều Khoản Dịch Vụ này. Điều Khoản Dịch Vụ này điều chỉnh
            việc bạn sử dụng Dịch Vụ cung cấp bởi The Bros.
          </div>
          <br />
          <div>
            1.2. Dịch Vụ bao gồm dịch vụ website kết nối đội ngũ thực hiện dự án
            và nhãn hàng nhằm mang đến cơ hội tài trợ giữa đội ngũ thực hiện dự
            án (“Bên Nhận Tài Trợ”) và nhãn hàng (“Nhà Tài Trợ”) (gọi chung là
            “bạn”, “Người Sử Dụng” hoặc “Các Bên”). Hợp đồng mua bán thật sự là
            trực tiếp giữa Bên Nhận Tài Trợ và Nhà Tài Trợ. Các Bên liên quan
            đến giao dịch đó sẽ chịu trách nhiệm đối với hợp đồng giao dịch giữa
            họ, việc triển khai, giải ngân và tương tự. TheBros có thể hoặc
            không sàng lọc trước Người Sử Dụng hoặc Nội Dung hoặc thông tin cung
            cấp bởi Người Sử Dụng. TheBros không bảo đảm cho việc các Người Sử
            Dụng sẽ thực tế hoàn thành giao dịch. Lưu ý, Bên Nhận Tài Trợ và Nhà
            Tài Trợ có thể tự giao dịch với nhau hoặc sử dụng Dịch Vụ của
            TheBros để chúng tôi là bên trung gian quản lý tình trạng triển khai
            và giải ngân giữa các bên.
          </div>
          <br />
          <div>
            1.3. Việc truy cập website thebros.vn, sau đây gọi là web của bạn,
            đồng nghĩa với việc bạn đồng ý với các Điều khoản và bảo mật của
            chúng tôi. Chúng tôi, có quyền thay đổi, chỉnh sửa, thêm hoặc lược
            bỏ bất kỳ điều khoản nào không trái với pháp luật Việt Nam. Các thay
            đổi có hiệu lực ngay khi được đăng trên trang web mà không cần thông
            báo trước. Và khi bạn tiếp tục sử dụng trang web, sau khi các thay
            đổi về quy định và điều kiện được đăng tải, có nghĩa là quý khách
            chấp nhận với những thay đổi đó.
          </div>
          <br />
          <div>
            1.4. TheBros bảo lưu quyền từ chối yêu cầu mở Tài Khoản hoặc các
            truy cập của bạn tới web hoặc Dịch Vụ theo quy định pháp luật và
            Điều khoản dịch vụ.
          </div>
          <div>
            <br />
            BẰNG VIỆC SỬ DỤNG DỊCH VỤ HAY TẠO TÀI KHOẢN TẠI THE BROS, BẠN ĐÃ
            CHẤP NHẬN VÀ ĐỒNG Ý VỚI NHỮNG ĐIỀU KHOẢN DỊCH VỤ NÀY VÀ CHÍNH SÁCH
            BỔ SUNG ĐƯỢC DẪN CHIẾU THEO ĐÂY.
            <br /> NẾU BẠN KHÔNG ĐỒNG Ý VỚI NHỮNG ĐIỀU KHOẢN DỊCH VỤ NÀY, VUI
            LÒNG KHÔNG SỬ DỤNG DỊCH VỤ HOẶC TRUY CẬP VÀO TRANG THE BROS.
          </div>
          <br />
          <div />
          <strong>2. Quyền riêng tư</strong>
          <br />
          <br />
          <div>
            2.1. Bằng cách sử dụng Dịch Vụ hoặc cung cấp thông tin trên Trang
            The Bros, Người Sử Dụng: <br />
            <br />
            a. cho phép TheBros thu thập, sử dụng, công bố và/hoặc xử lý các Nội
            Dung, dữ liệu cá nhân của bạn và Thông Tin Người Sử Dụng; <br />
            <br />
            b. đồng ý và công nhận rằng các thông tin được cung cấp trên Trang
            TheBros sẽ thuộc sở hữu chung của bạn và The Bros; và <br />
            <br />
            c. sẽ không, dù là trực tiếp hay gián tiếp, tiết lộ các Thông Tin
            Người Sử Dụng cho bất kỳ bên thứ ba nào, hoặc bằng bất kỳ phương
            thức nào cho phép bất kỳ bên thứ ba nào được truy cập hoặc sử dụng
            Thông Tin Người Dùng của bạn.
          </div>
          <br />
          <div>
            2.2. Trường hợp Người Sử Dụng sở hữu dữ liệu cá nhân của Người Sử
            Dụng khác thông qua việc sử dụng Dịch Vụ (“Bên Nhận Thông Tin”) theo
            đây đồng ý rằng, mình sẽ (i) tuân thủ mọi quy định pháp luật về bảo
            vệ an toàn thông tin cá nhân liên quan đến những thông tin đó; (ii)
            cho phép Người Sử Dụng là chủ sở hữu của các thông tin cá nhân mà
            Bên Nhận Thông Tin thu thập được (“Bên Tiết Lộ Thông Tin”) được phép
            xóa bỏ thông tin của mình được thu thập từ cơ sở dữ liệu của Bên
            Nhận Thông Tin; và (iii) cho phép Bên Tiết Lộ Thông Tin rà soát
            những thông tin đã được thu thập về họ bởi Bên Nhận Thông Tin, phù
            hợp với hoặc theo yêu cầu của các quy định pháp luật hiện hành.
          </div>
          <br />
          <div>
            2.3. Dịch vụ có thể bao gồm quảng cáo, có thể được nhắm mục tiêu đến
            nội dung hoặc thông tin trên dịch vụ, các truy vấn được thực hiện
            thông qua dịch vụ hoặc thông tin khác. Các loại và mức độ quảng cáo
            của exTalk trên dịch vụ có thể thay đổi. Khi xem xét exTalk cho phép
            bạn truy cập và sử dụng dịch vụ, bạn đồng ý rằng exTalk và các nhà
            cung cấp và đối tác bên thứ ba có thể đưa ra quảng cáo như vậy trên
            dịch vụ hoặc liên quan đến việc hiển thị nội dung hoặc thông tin từ
            các dịch vụ cho dù bạn hoặc người khác gửi.
          </div>
          <br />
          <strong>3. Giới hạn trách nhiệm</strong>
          <div>
            <br />
          </div>
          <div>
            TheBros trao cho Người Sử Dụng quyền phù hợp để truy cập và sử dụng
            các Dịch Vụ theo các điều khoản và điều kiện được quy định trong
            Điều Khoản Dịch Vụ này. Tất cả các Nội Dung, thương hiệu, nhãn hiệu
            dịch vụ, tên thương mại, biểu tượng và tài sản sở hữu trí tuệ khác
            độc quyền (“Tài Sản Sở Hữu Trí Tuệ”) hiển thị trên Trang TheBros đều
            thuộc sở hữu của TheBros và bên sở hữu thứ ba, nếu có. Không một bên
            nào truy cập vào Trang TheBros được cấp quyền hoặc cấp phép trực
            tiếp hoặc gián tiếp để sử dụng hoặc sao chép bất kỳ Tài Sản Sở Hữu
            Trí Tuệ nào, cũng như không một bên nào truy cập vào Trang TheBros
            được phép truy đòi bất kỳ quyền, quyền sở hữu hoặc lợi ích nào liên
            quan đến Tài Sản Sở Hữu Trí Tuệ. Bằng cách sử dụng hoặc truy cập
            Dịch Vụ, bạn đồng ý tuân thủ các quy định pháp luật liên quan đến
            bản quyền, thương hiệu, nhãn hiệu dịch vụ hoặc bất cứ quy định pháp
            luật nào khác bảo vệ Dịch Vụ, Trang TheBros và Nội Dung của Trang
            The Bros. Bạn đồng ý không được phép sao chép, phát tán, tái bản,
            chuyển giao, công bố công khai, thực hiện công khai, sửa đổi, phỏng
            tác, cho thuê, bán, hoặc tạo ra các sản phẩm phái sinh của bất cứ
            phần nào thuộc Dịch Vụ, Trang TheBros và Nội Dung của Trang The
            Bros. Bạn không được nhân bản hoặc chỉnh sửa bất kỳ phần nào hoặc
            toàn bộ nội dung của Trang TheBros trên bất kỳ máy chủ hoặc như là
            một phần của bất kỳ website nào khác mà chưa nhận được sự chấp thuận
            bằng văn bản của The Bros. Ngoài ra, bạn đồng ý rằng bạn sẽ không sử
            dụng bất kỳ robot, chương trình do thám (spider) hay bất kỳ thiết bị
            tự động hoặc phương thức thủ công nào để theo dõi hoặc sao chép Nội
            Dung của TheBros khi chưa có sự đồng ý trước bằng văn bản của
            TheBros (sự chấp thuận này được xem như áp dụng cho các công cụ tìm
            kiếm cơ bản trên các website tìm kiếm trên mạng kết nối người dùng
            trực tiếp đến website đó).
          </div>
          <br />
          <strong>4. Tài khoản và bảo mật</strong>
          <div>
            <br />
          </div>
          <div>
            4.1. Một vài tính năng của Dịch Vụ chúng tôi yêu cầu phải đăng ký
            một Tài Khoản bằng bằng email của bạn (Tên Đăng Nhập) và mật khẩu
            đồng thời cung cấp một số thông tin cá nhân nhất định.
          </div>
          <br />
          <div>
            4.2. Bạn đồng ý (a) giữ bí mật mật khẩu và chỉ sử dụng Tên Đăng Nhập
            và mật khẩu khi đăng nhập, (b) đảm bảo bạn sẽ đăng xuất khỏi tài
            khoản của mình sau mỗi phiên đăng nhập trên Trang The Bros, và (c)
            thông báo ngay lập tức với TheBros nếu phát hiện bất kỳ việc sử dụng
            trái phép nào đối với Tài Khoản, Tên Đăng Nhập và/hoặc mật khẩu của
            bạn. Bạn phải chịu trách nhiệm với hoạt động dưới Tên Đăng Nhập và
            Tài Khoản của mình, bao gồm tổn thất hoặc thiệt hại phát sinh từ
            việc sử dụng trái phép liên quan đến mật khẩu hoặc từ việc không
            tuân thủ Điều Khoản này của Người Sử Dụng.
          </div>
          <br />
          <div>
            4.3. Bạn đồng ý rằng TheBros có toàn quyền xóa Tài Khoản và Tên Đăng
            Nhập của Người Sử Dụng ngay lập tức, gỡ bỏ hoặc hủy từ Trang TheBros
            bất kỳ Nội Dung nào liên quan đến Tài Khoản và Tên Đăng Nhập của
            Người Sử Dụng với bất kỳ lý do nào mà có hoặc không cần thông báo
            hay chịu trách nhiệm với Người Sử Dụng hay bên thứ ba nào khác. Căn
            cứ để thực hiện các hành động này có thể bao gồm (a) Tài Khoản và
            Tên Đăng Nhập không hoạt động trong thời gian dài, (b) vi phạm điều
            khoản hoặc tinh thần của các Điều Khoản Dịch Vụ này, (c) có hành vi
            bất hợp pháp, lừa đảo, quấy rối, xâm phạm, đe dọa hoặc lạm dụng, (d)
            có nhiều tài khoản người dùng khác nhau, (e) có hành vi gây hại tới
            những Người Sử Dụng khác, các bên thứ ba hoặc các lợi ích kinh tế
            của The Bros. Việc sử dụng Tài Khoản cho các mục đích bất hợp pháp,
            lừa đảo, quấy rối, xâm phạm, đe dọa hoặc lạm dụng có thể được gửi
            cho cơ quan nhà nước có thẩm quyền theo quy định pháp luật.
          </div>
          <br />
          <div>
            4.4. Người Sử Dụng có thể yêu cầu xóa tài khoản bằng cách thông báo
            bằng văn bản đến The Bros. Tuy nhiên, Người Sử Dụng vẫn phải chịu
            trách nhiệm và nghĩa vụ đối với bất kỳ giao dịch nào chưa hoàn thành
            (phát sinh trước hoặc sau khi tài khoản bị xóa) hay việc vận hành dự
            án đến tài khoản bị yêu cầu xóa. Khi đó, theo Điều Khoản Dịch Vụ,
            Người Sử Dụng phải liên hệ với TheBros sau khi đã nhanh chóng và
            hoàn tất việc thực hiện và hoàn thành các giao dịch chưa hoàn tất.
            Người Sử Dụng chịu trách nhiệm đối với yêu cầu xóa tài khoản của
            mình.
          </div>
          <br />
          <div>
            4.5. Bạn chỉ có thể sử dụng Dịch Vụ và/hoặc mở Tài Khoản tại TheBros
            nếu bạn đáp ứng đủ các điều kiện để chấp nhận Điều Khoản Dịch Vụ
            này.
          </div>
          <br />
          <strong>5. Điều khoản sử dụng</strong>
          <div>
            <br />
          </div>
          <div>
            5.1. Quyền được phép sử dụng Trang TheBros và Dịch Vụ có hiệu lực
            cho đến khi bị chấm dứt. Quyền được phép sử dụng sẽ bị chấm dứt theo
            Điều Khoản Dịch Vụ này hoặc trường hợp Người Sử Dụng vi phạm bất cứ
            điều khoản hoặc điều kiện nào được quy định tại Điều Khoản Dịch Vụ
            này. Trong trường hợp đó, TheBros có thể chấm dứt việc sử dụng của
            Người Sử Dụng bằng hoặc không cần thông báo.
          </div>
          <br />
          <div>5.2. Người Sử Dụng không được phép:</div>
          <br />
          <div>
            (a) tải lên, đăng, truyền tải hoặc bằng cách khác công khai bất cứ
            Nội Dung nào trái pháp luật, có hại, đe dọa, lạm dụng, quấy rối, gây
            hoang mang, lo lắng, xuyên tạc, phỉ báng, xúc phạm, khiêu dâm, bôi
            nhọ, xâm phạm quyền riêng tư của người khác, gây căm phẫn, hoặc phân
            biệt chủng tộc, dân tộc hoặc bất kỳ nội dung không đúng đắn nào
            khác; <br /> <br />
            (b) vi phạm pháp luật, quyền lợi của bên thứ ba; <br /> <br />
            (c) đăng tải, truyền tin, hoặc bằng bất kỳ hình thức nào khác hiển
            thị bất kỳ Nội dung nào có sự xuất hiện của người chưa thành niên
            hoặc sử dụng Dịch vụ gây tổn hại cho người chưa thành niên dưới bất
            kỳ hình thức nào;
            <br /> <br />
            (d) sử dụng Dịch Vụ hoặc đăng tải Nội Dung để mạo danh bất kỳ cá
            nhân hoặc tổ chức nào, hoặc bằng cách nào khác xuyên tạc cá nhân
            hoặc tổ chức; <br /> <br />
            (e) giả mạo các tiêu đề hoặc bằng cách khác ngụy tạo các định dạng
            nhằm che giấu nguồn gốc của bất kỳ Nội Dung nào được truyền tải
            thông qua Dịch Vụ; <br /> <br />
            (f) gỡ bỏ bất kỳ thông báo độc quyền nào từ Trang The Bros;
            <br /> <br />
            (g) gây ra, chấp nhận hoặc ủy quyền cho việc sửa đổi, cấu thành các
            sản phẩm phái sinh, hoặc chuyển thể của Dịch Vụ mà không được sự cho
            phép rõ ràng của The Bros; <br /> <br />
            (h) sử dụng Dịch Vụ phục vụ lợi ích của bất kỳ bên thứ ba nào hoặc
            bất kỳ hành vi nào không được chấp nhận theo Điều Khoản Dịch Vụ này;
            <br /> <br />
            (i) sử dụng Dịch Vụ hoặc đăng tải Nội Dung cho mục đích gian lận,
            không hợp lý, sai trái, gây hiểu nhầm hoặc gây nhầm lẫn; <br />
            <br />
            (j) mở và vận hành nhiều tài khoản người dùng khác nhau liên quan
            đến bất kỳ hành vi nào vi phạm điều khoản hoặc tinh thần của Điều
            Khoản Dịch Vụ này;
            <br /> <br />
            (k) truy cập website The Bros, mở tài khoản hoặc bằng cách khác truy
            cập tài khoản người dùng của mình thông qua bất kỳ phần mềm hoặc
            phần cứng không được chấp thuận hoặc cung cấp bởi The Bros, bao gồm
            phần mềm giả lập, thiết bị giả lập, phần mềm tự động hoặc bất kỳ
            phần mềm, thiết bị hoặc phần cứng nào có chức năng tương tự.
            <br /> <br />
            (l) chỉnh sửa hoặc can thiệp thông tin dự án của Người Sử Dụng khác.
            <br /> <br />
            (m) thực hiện bất kỳ hành động nào làm sai lệch hệ thống đánh giá
            hoặc tiếp nhận phản hồi của The Bros; <br /> <br />
            (n) cố gắng chuyển đổi mã chương trình, đảo ngược kỹ thuật, tháo gỡ
            hoặc xâm nhập (hack) Dịch Vụ (hoặc bất cứ hợp phần nào theo đó);
            hoặc phá bỏ hoặc vượt qua bất kỳ công nghệ mã hóa hoặc biện pháp bảo
            mật nào được TheBros áp dụng đối với các Dịch Vụ và/hoặc các dữ liệu
            được truyền tải, xử lý hoặc lưu trữ bởi The Bros; <br />
            <br />
            (o) khai thác hoặc thu thập bất kỳ thông tin nào liên quan đến Tài
            Khoản của Người Sử Dụng khác, bao gồm bất kỳ thông tin hoặc dữ liệu
            cá nhân nào; <br /> <br />
            (p) tải lên, gửi thư điện tử, đăng, chuyển tải hoặc bằng cách khác
            công khai bất kỳ Nội Dung nào mà bạn không được cho phép theo bất kỳ
            luật hoặc quan hệ hợp đồng hoặc tín thác nào (chẳng hạn thông tin
            nội bộ, thông tin độc quyền và thông tin mật được biết hoặc tiết lộ
            như một phần của quan hệ lao động hoặc theo các thỏa thuận bảo mật);
            <br /> <br />
            (q) tải lên, gửi thư điện tử, đăng, chuyển tải hoặc bằng cách khác
            công khai bất kỳ Nội Dung nào dẫn đến trường hợp vi phạm các quyền
            về sáng chế, thương hiệu, bí quyết kinh doanh, bản quyền hoặc bất cứ
            đặc quyền nào của bất cứ bên nào; <br /> <br />
            (r) tải lên, gửi thư điện tử, đăng, chuyển tải hoặc bằng cách khác
            công khai bất kỳ quảng cáo, các tài liệu khuyến mại, “thư quấy rối”,
            “thư rác”, “chuỗi ký tự” không được phép hoặc không hợp pháp, hoặc
            bất kỳ hình thức lôi kéo không được phép nào khác; <br /> <br />
            (s) tải lên, gửi thư điện tử, đăng, chuyển tải hoặc bằng cách khác
            công khai bất cứ tài liệu chứa các loại vi-rút, worm, trojan hoặc
            bất kỳ các mã, tập tin hoặc chương trình máy tính được thiết kế để
            trực tiếp hoặc gián tiếp gây cản trở, điều khiển, gián đoạn, phá
            hỏng hoặc hạn chế các chức năng hoặc tổng thể của bất kỳ phần mềm
            hoặc phần cứng hoặc dữ liệu hoặc thiết bị viễn thông của máy tính;
            <br /> <br />
            (t) làm gián đoạn các dòng tương tác thông thường, đẩy nhanh tốc độ
            “lướt (scroll)” màn hình hơn những Người Sử Dụng khác có thể đối với
            Dịch Vụ, hoặc bằng cách khác thực hiện thao tác gây ảnh hưởng tiêu
            cực đến khả năng tham gia giao dịch thực của những Người Sử Dụng
            khác, <br /> <br />
            (u) can thiệp, điều khiển hoặc làm gián đoạn Dịch Vụ hoặc máy chủ
            hoặc hệ thống được liên kết với Dịch Vụ hoặc tới việc sử dụng và
            trải nghiệm Dịch Vụ của Người Sử Dụng khác, hoặc không tuân thủ bất
            kỳ các yêu cầu, quy trình, chính sách và luật lệ đối với các hệ
            thống được liên kết với Trang The Bros; <br /> <br />
            (v) thực hiện bất kỳ hành động hoặc hành vi nào có thể trực tiếp
            hoặc gián tiếp phá hủy, vô hiệu hóa, làm quá tải, hoặc làm suy yếu
            Dịch Vụ hoặc máy chủ hoặc hệ thống liên kết với Dịch Vụ; <br />
            <br />
            (w) sử dụng Dịch Vụ để vi phạm pháp luật, quy chế, quy tắc, chỉ thị,
            hướng dẫn, chính sách áp dụng của địa phương, liên bang, quốc gia
            hoặc quốc tế (có hoặc chưa có hiệu lực) một cách có chủ ý hoặc vô ý
            liên quan đến phòng chống rửa tiền hoặc phòng chống khủng bố; <br />
            <br />
            (x) sử dụng Dịch Vụ để vi phạm hoặc phá vỡ bất kỳ hình phạt hay lệnh
            cấm vận nào được quản lý hay thực thi bởi các cơ quan có liên quan.
            <br /> <br />
            (y) sử dụng Dịch Vụ để xâm hại tới quyền riêng tư của người khác
            hoặc để “lén theo dõi” hoặc bằng cách khác quấy rối người khác;
            <br /> <br />
            (z) xâm phạm các quyền của The Bros, bao gồm bất kỳ quyền sở hữu trí
            tuệ và gây nhầm lẫn cho các quyền đó; <br /> <br />
            (aa) sử dụng Dịch vụ để thu thập hoặc lưu trữ dữ liệu cá nhân của
            Người Sử Dụng khác liên quan đến các hành vi và hoạt động bị cấm như
            đề cập ở trên; và/hoặc <br /> <br />
            (ab) liệt kê các hàng hóa xâm phạm quyền tác giả, nhãn hiệu hoặc các
            quyền sở hữu trí tuệ khác của các bên thứ ba hoặc sử dụng Dịch Vụ
            theo các cách thức có thể xâm phạm đến quyền sở hữu trí tuệ của các
            bên khác.
          </div>
          <br />
          <div>
            5.3. Người Sử Dụng hiểu rằng các Nội Dung, dù được đăng công khai
            hoặc truyền tải riêng tư, là hoàn toàn thuộc trách nhiệm của người
            đã tạo ra Nội Dung đó. Điều đó nghĩa là bạn, không phải The Bros,
            phải hoàn toàn chịu trách nhiệm đối với những Nội Dung mà bạn tải
            lên, đăng, gửi thư điện tử, chuyển tải hoặc bằng cách nào khác công
            khai trên Trang The Bros. Người Sử Dụng hiểu rằng bằng cách sử dụng
            Trang The Bros, bạn có thể gặp phải Nội Dung mà bạn cho là phản cảm,
            không đúng đắn hoặc chưa phù hợp. TheBros sẽ không chịu trách nhiệm
            đối với Nội Dung, bao gồm lỗi hoặc thiếu sót liên quan đến Nội Dung,
            hoặc tổn thất hoặc thiệt hại xuất phát từ việc sử dụng, hoặc dựa
            trên, Nội Dung được đăng tải, gửi thư, chuyển tải hoặc bằng cách
            khác công bố trên Trang The Bros.
          </div>
          <br />
          <div>
            5.4. Người Sử Dụng thừa nhận rằng TheBros và các bên được chỉ định
            của mình có toàn quyền (nhưng không có nghĩa vụ) sàng lọc, từ chối,
            xóa, dừng, tạm dừng, gỡ bỏ hoặc dời bất kỳ Nội Dung có sẵn trên
            Trang The Bros, bao gồm bất kỳ Nội Dung hoặc thông tin nào bạn đã
            đăng. TheBros có quyền gỡ bỏ Nội Dung (i) xâm phạm đến Điều Khoản
            Dịch Vụ; (ii) trong trường hợp TheBros nhận được khiếu nại hơp lệ
            theo quy định pháp luật từ Người Sử Dụng khác; (iii) trong trường
            hợp TheBros nhận được thông báo hợp lệ về vi phạm quyền sở hữu trí
            tuệ hoặc yêu cầu pháp lý cho việc gỡ bỏ; hoặc (iv) những nguyên nhân
            khác theo quy định pháp luật. TheBros có quyền chặn các liên lạc
            (bao gồm việc cập nhật trạng thái, đăng tin, truyền tin và/hoặc tán
            gẫu) về hoặc liên quan đến Dịch Vụ như nỗ lực của chúng tôi nhằm bảo
            vệ Dịch Vụ hoặc Người Sử Dụng của The Bros, hoặc bằng cách khác thi
            hành các điều khoản trong Điều Khoản Dịch Vụ này. Người Sử Dụng đồng
            ý rằng mình phải đánh giá, và chịu mọi rủi ro liên quan đến, việc sử
            dụng bất kỳ Nội Dung nào, bao gồm bất kỳ việc dựa vào tính chính
            xác, đầy đủ, hoặc độ hữu dụng của Nội Dung đó. Liên quan đến vấn đề
            này, Người Sử Dụng thừa nhận rằng mình không phải và, trong giới hạn
            tối đa pháp luật cho phép, không cần dựa vào bất kỳ Nội Dung nào
            được tạo bởi TheBros hoặc gửi cho The Bros, bao gồm các thông tin
            trên các Diễn Đàn TheBros hoặc trên các phần khác của Trang The
            Bros.
          </div>
          <br />
          <div>
            5.5. Người Sử Dụng chấp thuận và đồng ý rằng TheBros có thể truy
            cập, duy trì và tiết lộ thông tin Tài Khoản của Người Sử Dụng trong
            trường hợp pháp luật có yêu cầu hoặc theo lệnh của tòa án hoặc cơ
            quan chính phủ hoặc cơ quan nhà nước có thẩm quyền yêu cầu TheBros
            hoặc những nguyên nhân khác theo quy định pháp luật: (a) tuân thủ
            các thủ tục pháp luật; (b) thực thi Điều Khoản Dịch Vụ; (c) phản hồi
            các khiếu nại về việc Nội Dung xâm phạm đến quyền lợi của bên thứ
            ba; (d) phản hồi các yêu cầu của Người Sử Dụng liên quan đến chăm
            sóc khách hàng; hoặc (e) bảo vệ các quyền, tài sản hoặc an toàn của
            The Bros, Người Sử Dụng và/hoặc cộng đồng.
          </div>
          <br />
          <br />
          <strong>6. Vi phạm điều khoản dịch vụ</strong>
          <div>
            <br />
          </div>
          <div>
            6.1. Việc vi phạm chính sách này có thể dẫn tới một số hành động,
            bao gồm bất kỳ hoặc tất cả các hành động sau: <br />- Xóa danh mục
            các dự án và thông tin đã đăng tải; <br />- Giới hạn quyền sử dụng
            Tài Khoản;
            <br /> - Đình chỉ và chấm dứt Tài Khoản; <br />- Thu hồi tiền/tài
            sản có được do hành vi gian lận, và các chi phí có liên quan như chi
            phí vận chuyển của đơn hàng, phí thanh toán…; <br />- Cáo buộc hình
            sự; <br />- Áp dụng biện pháp dân sự, bao gồm khiếu nại bồi thường
            thiệt hại và/hoặc áp dụng biện pháp khẩn cấp tạm thời; <br />- Các
            hành động hoặc biện pháp chế tài khác.
          </div>
          <br />
          <div>
            6.2. Nếu bạn phát hiện Người Sử Dụng trên Trang TheBros của chúng
            tôi có hành vi vi phạm Điều Khoản Dịch Vụ, vui lòng liên hệ TheBros
            tại đây.
          </div>
          <br />
          <strong>
            7. Báo cáo hành vi có khả năng xâm phạm quyền sở hữu trí tuệ
          </strong>
          <br />
          <br />
          <div>
            7.1. Người Sử Dụng là các cá nhân hoặc tổ chức độc lập và họ không
            có bất kỳ mối liên kết nào với TheBros dưới bất kỳ hình thức nào.
            TheBros cũng không phải là đại lý hay đại diện của Người Sử Dụng và
            không nắm giữ và/hoặc sở hữu bất kỳ dự án nào được chào bán trên
            Trang The Bros.
          </div>
          <br />
          <div>
            7.2. Nếu bạn là Chủ Sở Hữu Quyền Sở Hữu Trí Tuệ (“Chủ Sở Hữu Quyền
            SHTT”) hoặc là đại diện được ủy quyền Chủ Sở Hữu Quyền SHTT (“Đại
            Diện Quyền SHTT”) và bạn tin rằng các quyền của bản thân hoặc của
            thân chủ có khả năng bị xâm phạm, vui lòng sử dụng đơn khiếu nại mẫu
            Tại Đây đính kèm đầy đủ các tài liệu hợp lệ chứng minh sự tồn tại và
            sở hữu hợp pháp của người khiếu nại đối với quyền sở hữu trí tuệ
            được bảo hộ trong phạm vi lãnh thổ có liên quan, cũng như chứng cứ
            về hành vi xâm phạm quyền sở hữu trí tuệ trong các đăng bán bị khiếu
            nại cụ thể. Chúng tôi sẽ dành một khoảng thời gian hợp lý để xử lý
            các thông tin cung cấp.
          </div>
          <br />
          <strong>8. Tranh chấp.</strong>
          <br />
          <br />
          <div>
            8.1. Trường hợp phát sinh vấn đề liên quan đến giao dịch, Bên Nhận
            Tài Trợ và Nhà Tài trợ đồng ý đầu tiên sẽ đối thoại với nhau để cố
            gắng giải quyết tranh chấp đó bằng thảo luận hai bên, và TheBros sẽ
            cố gắng một cách hợp lý để thu xếp. Nếu vấn đề không được giải quyết
            bằng thảo luận hai bên, Người Sử Dụng có thể khiếu nại lên cơ quan
            có thẩm quyền để giải quyết tranh chấp phát sinh đối với giao dịch.
          </div>
          <br />
          <div>
            8.2. Mỗi Bên Nhận Tài Trợ và Nhà Tài trợ cam kết và đồng ý rằng mình
            sẽ không tiến hành thủ tục tố tụng hoặc bằng cách khác khiếu nại đối
            với TheBros hoặc các công ty liên kết của TheBros (trừ trường hợp
            TheBros hoặc các công ty liên kết của mình là Người Bán sản phẩm
            liên quan đến khiếu nại đó) liên quan đến bất kỳ giao dịch nào được
            thực hiện trên Trang TheBros hoặc bất kỳ tranh chấp nào liên quan
            đến giao dịch đó.
          </div>
          <br />
          <strong>9. Loại trừ trách nhiệm</strong>
          <br />
          <br />
          <div>
            9.1. DỊCH VỤ ĐƯỢC CUNG CẤP NHƯ ‘SẴN CÓ’ VÀ KHÔNG CÓ BẤT KỲ SỰ ĐẢM
            BẢO, KHIẾU NẠI HOẶC KHẲNG ĐỊNH NÀO TỪ TheBros VỀ BẤT KỲ NỘI DUNG NÀO
            ĐƯỢC THỂ HIỆN, NGỤ Ý HOẶC BẮT BUỘC ĐỐI VỚI DỊCH VỤ, BAO GỒM VIỆC ĐẢM
            BẢO VỀ CHẤT LƯỢNG, VIỆC THỰC HIỆN, KHÔNG VI PHẠM, VIỆC MUA BÁN, HAY
            SỰ PHÙ HỢP CHO MỘT MỤC ĐÍCH CỤ THỂ, CŨNG NHƯ KHÔNG CÓ BẤT KỲ SỰ ĐẢM
            BẢO NÀO ĐƯỢC TẠO RA TRONG QUÁ TRÌNH GIAO DỊCH, THỰC HIỆN, VẬN HÀNH
            DỰ ÁN.
          </div>
          <br />
          <div>
            9.2. NGƯỜI SỬ DỤNG CẦN THỪA NHẬN RẰNG MỌI RỦI RO PHÁT SINH NGOÀI
            VIỆC SỬ DỤNG HOẶC VẬN HÀNH CỦA TRANG TheBros VÀ/HOẶC DỊCH VỤ TheBros
            SẼ THUỘC VỀ NGƯỜI SỬ DỤNG TRONG GIỚI HẠN TỐI ĐA PHÁP LUẬT CHO PHÉP.
          </div>
          <br />
          <div>
            9.3. TheBros không kiểm soát và không đảm bảo hoặc chấp nhận trách
            nhiệm đối với: (a) việc phù hợp mục đích, sự tồn tại, chất lượng, độ
            an toàn hoặc tính pháp lý của các dự án có sẵn trên trang The Bros;
            hoặc (b) khả năng bên nhận tài trợ xin tài trợ cho các dự án của
            mình hoặc khả năng của nhà tài trợ đồng ý và tài trợ cho các dự án.
            Nếu có tranh chấp liên quan đến một hoặc nhiều người sử dụng, những
            người sử dụng này đồng ý tự giải quyết tranh chấp trực tiếp với nhau
            và miễn trừ TheBros và các công ty liên kết của TheBros khỏi khiếu
            nại, yêu cầu và tổn thất phát sinh hoặc liên quan đến tranh chấp.
          </div>
          <br />
          <strong>10. Các loại trừ và giới hạn trách nhiệm</strong>
          <br />
          <br />
          <div>
            10.1. TheBros KHÔNG CHỊU TRÁCH NHIỆM PHÁT SINH TỪ HỢP ĐỒNG, VẬN
            HÀNH, KHỦNG HOẢNG (BAO GỒM NHỮNG BẤT CẨN (DÙ CHỦ ĐỘNG HAY BỊ ĐỘNG),
            TRÁCH NHIỆM ĐỐI VỚI DỰ ÁN, TRÁCH NHIỆM PHÁP LÝ HOẶC TRÁCH NHIỆM
            KHÁC), HOẶC NGUYÊN NHÂN KHÁC LIÊN QUAN ĐẾN LUẬT PHÁP, QUYỀN LỢI
            CHÍNH ĐÁNG, CÁC QUY CHẾ HOẶC CÁC HÌNH THỨC KHÁC ĐỐI VỚI
          </div>
          <br />
          <div>
            (i) (A) TỔN THẤT VỀ SỬ DỤNG; (B) TỔN THẤT VỀ LỢI NHUẬN; (C) TỔN THẤT
            VỀ DOANH THU; (D) TỔN THẤT VỀ DỮ LIỆU; (E) TỔN THẤT VỀ UY TÍN; HOẶC
            (F) KHÔNG THỰC HIỆN ĐƯỢC CÁC BIỆN PHÁP NGUY CẤP DỰ TRÙ, ĐỐI VỚI TỪNG
            TRƯỜNG HỢP DÙ TRỰC TIẾP HOẶC GIÁN TIẾP; HOẶC <br />
            (ii) THIỆT HẠI GIÁN TIẾP, NGẪU NHIÊN, ĐẶC BIỆT HOẶC MANG TÍNH HỆ QUẢ
            NÀO (GỒM BẤT KỲ MẤT MÁT NÀO VỀ DỮ LIỆU, GIÁN ĐOẠN DỊCH VỤ, MÁY TÍNH,
            ĐIỆN THOẠI HOẶC CÁC THIẾT BỊ DI ĐỘNG KHÁC) PHÁT SINH TỪ HOẶC LIÊN
            QUAN ĐẾN VIỆC SỬ DỤNG TRANG TheBros HOẶC DỊCH VỤ, BAO GỒM THIỆT HẠI
            PHÁT SINH TỪ ĐÓ, NGAY CẢ KHI TheBros ĐÃ ĐƯỢC THÔNG BÁO VỀ KHẢ NĂNG
            CỦA CÁC THIỆT HẠI ĐÓ HOẶC ĐƯỢC GỢI Ý PHẢI CHỊU TRÁCH NHIỆM.
          </div>
          <br />
          <div>
            10.2. BẠN CÓ QUYỀN YÊU CẦU CHẤM DỨT TÀI KHOẢN CỦA BẠN VÀ/HOẶC DỪNG
            SỬ DỤNG DỊCH VỤ TRONG TRƯỜNG HỢP KHÔNG THỎA MÃN VỚI DỊCH VỤ.
          </div>
          <br />
          <div>
            10.3. KHÔNG CÓ NỘI DUNG NÀO TRONG ĐIỀU KHOẢN DỊCH VỤ NÀY SẼ GIỚI HẠN
            HOẶC LOẠI TRỪ BẤT KỲ TRÁCH NHIỆM NÀO ĐỐI VỚI THƯƠNG VONG HOẶC TAI
            NẠN CÁ NH N DO SỰ BẤT CẨN, GIAN LẬN CỦA TheBros HOẶC BẤT KỲ TRÁCH
            NHIỆM NÀO KHÁC CÓ PHẦN THAM GIA CỦA TheBros MÀ TRÁCH NHIỆM ĐÓ KHÔNG
            ĐƯỢC HẠN CHẾ VÀ/HOẶC LOẠI TRỪ THEO QUY ĐỊNH PHÁP LUẬT.
          </div>
          <br />
          <br />
          <strong>11. Khẳng định và đảm bảo của người sử dụng</strong>
          <br />
          <br />
          <div>
            Người Sử Dụng khẳng định và đảm bảo rằng: <br /> <br />
            (a) Người Sử Dụng sở hữu năng lực, (và đối với trường hợp vị thành
            niên, có sự đồng ý hợp lệ từ cha mẹ hoặc người giám hộ hợp pháp),
            quyền và khả năng hợp pháp để giao kết các Điều Khoản Dịch Vụ này và
            để tuân thủ các điều khoản theo đó; và <br />
            (b) Người Sử Dụng chỉ sử dụng Dịch Vụ cho các mục đích hợp pháp và
            theo quy định của các Điều Khoản Dịch Vụ này cũng như tất cả các
            luật, quy tắc, quy chuẩn, chỉ thị, hướng dẫn, chính sách và quy định
            áp dụng.
          </div>
          <br />
          <strong>12. Tính riêng lẻ</strong>
          <br />
          <br />
          <div>
            Nếu bất kì điều khoản nào trong Điều Khoản Dịch Vụ này không hợp
            pháp, bị bãi bỏ, hoặc vì bất kỳ lý do nào không thể thực thi theo
            pháp luật, thì điều khoản đó sẽ được tách ra khỏi các điều khoản và
            điều kiện này và sẽ không ảnh hưởng tới hiệu lực cũng như tính thi
            hành của bất kỳ điều khoản còn lại nào cũng như không ảnh hưởng tới
            hiệu lực cũng như tính thi hành của điều khoản sẽ được xem xét theo
            luật.
          </div>
          <br />
          <strong> 13. Luật áp dụng</strong>
          <br />
          <br />
          <div>
            Nếu bất kì điều khoản nào trong Điều Khoản Dịch Vụ này không hợp
            pháp, bị bãi bỏ, hoặc vì bất kỳ lý do nào không thể thực thi theo
            pháp luật, thì điều khoản đó sẽ được tách ra khỏi các điều khoản và
            điều kiện này và sẽ không ảnh hưởng tới hiệu lực cũng như tính thi
            hành của bất kỳ điều khoản còn lại nào cũng như không ảnh hưởng tới
            hiệu lực cũng như tính thi hành của điều khoản sẽ được xem xét theo
            luật.
          </div>
          <br />
          <strong>14. Quy định chung</strong>
          <div>
            <br />
          </div>
          <div>
            14.1. TheBros bảo lưu tất cả các quyền lợi không được trao theo đây.
          </div>
          <br />
          <div>
            14.2. TheBros có quyền chỉnh sửa các Điều Khoản Dịch Vụ này vào bất
            cứ thời điểm nào thông qua việc đăng tải các Điều Khoản Dịch Vụ được
            chỉnh sửa lên Trang The Bros. Việc Người Dùng tiếp tục sử dụng Trang
            TheBros sau khi việc thay đổi được đăng tải sẽ cấu thành việc Người
            Sử Dụng chấp nhận các Điều Khoản Dịch Vụ được chỉnh sửa.
          </div>
          <br />
          <div>
            14.3. Người Sử Dụng không được phép chuyển giao, cấp lại quyền hoặc
            chuyển nhượng bất kỳ các quyền hoặc nghĩa vụ được cấp cho Người Sử
            Dụng theo đây.
          </div>
          <br />
          <div>
            14.4. Không một quy định nào trong các Điều Khoản Dịch Vụ này sẽ cấu
            thành quan hệ đối tác, liên doanh hoặc quan hệ đại lý – chủ sở hữu
            giữa bạn và The Bros.
          </div>
          <br />
          <div>
            14.5. Tại bất kỳ hoặc các thời điểm nào, nếu TheBros không thể thực
            hiện được bất kỳ điều khoản nào theo đây sẽ không ảnh hưởng, dưới
            bất kỳ hình thức nào, đến các quyền của TheBros vào thời điểm sau đó
            để thực thi các quyền này trừ khi việc thư thi các quyền này được
            miễn trừ bằng văn bản.
          </div>
          <br />
          <div>
            14.6. Các Điều Khoản Dịch Vụ này hoàn toàn phục vụ cho lợi ích của
            TheBros và Người Sử Dụng mà vì lợi ích của bất kỳ cá nhân hay tổ
            chức nào khác, trừ các công ty liên kết và các công ty con của The A
            List.
          </div>
          <br />
          <div>
            14.7. Các điều khoản được quy định trong Điều Khoản Dịch Vụ này và
            bất kỳ các thỏa thuận và chính sách được bao gồm hoặc được dẫn chiếu
            trong các Điều Khoản Dịch Vụ này cấu thành nên sự thỏa thuận và cách
            hiểu tổng thể của các bên đối với Dịch Vụ và Trang TheBros và thay
            thế bất kỳ thỏa thuận hoặc cách hiểu trước đây giữa các bên liên
            quan đến vấn đề đó. Với việc các bên giao kết thỏa thuận được tạo
            thành theo các Điều Khoản Dịch Vụ này, các bên không dựa vào bất kỳ
            tuyên bố, khẳng định, đảm bảo, cách hiểu, cam kết, lời hứa hoặc cam
            đoan nào của bất kỳ người nào trừ những điều được nêu rõ trong các
            Điều Khoản Dịch Vụ này. Các Điều Khoản Dịch Vụ này có thể sẽ không
            mâu thuẫn, giải thích hoặc bổ sung như là bằng chứng của các thỏa
            thuận trước, bất kỳ thỏa thuận miệng hiện tại nào hoặc bất kỳ các
            điều khoản bổ sung nhất quán nào.
          </div>
          <br />
          <div>
            14.8. Bạn đồng ý tuân thủ mọi quy định pháp luật hiện hành liên quan
            đến chống tham nhũng và chống hối lộ.
          </div>
          <br />
          <div>
            14.9. Nếu bạn có bất kỳ câu hỏi hay thắc mắc nào liên quan đến Điều
            Khoản Dịch Vụ hoặc các vấn đề phát sinh liên quan đến Điều Khoản
            Dịch Vụ trên Trang The Bros, vui lòng liên hệ TheBros tại đây.
          </div>
          <br />
          TÔI ĐÃ ĐỌC CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY VÀ ĐỒNG Ý VỚI TẤT CẢ CÁC ĐIỀU
          KHOẢN NHƯ TRÊN CŨNG NHƯ BẤT KỲ ĐIỀU KHOẢN NÀO ĐƯỢC CHỈNH SỬA SAU NÀY.
          BẰNG CÁCH BẤM NÚT “ĐĂNG KÝ” KHI ĐĂNG KÝ SỬ DỤNG TRANG THE BROS, TÔI
          HIỂU RẰNG TÔI ĐANG TẠO CHỮ KÝ ĐIỆN TỬ MÀ TÔI HIỂU RẰNG NÓ CÓ GIÁ TRỊ
          VÀ HIỆU LỰC TƯƠNG TỰ NHƯ CHỮ KÝ TÔI KÝ BẰNG TAY.
          <br />
          <br />
          <div>Hiệu lực: ngày 23 tháng 09 năm 2022</div>
          <div>Cập nhật lần cuối: ngày 23 tháng 09 năm 2022</div>
        </div>
      </div>
    </div>
  );
};

export default Term;
