import React, { useCallback } from "react";
import { default as CD } from "react-countdown";
import "./Countdown.scss";
const Completionist = () => <span className="text count-down-text">00:00</span>;

const renderer = ({ hours, minutes, seconds, completed, onTimeUp }: any) => {
  if (completed) {
    onTimeUp();
    return <Completionist />;
  } else {
    return (
      <span className="text count-down-text">
        {minutes.toString().length === 1 ? `0${minutes}` : minutes}:
        {seconds.toString().length === 1 ? `0${seconds}` : seconds}
      </span>
    );
  }
};

const Countdown = (props: any) => {
  const { date, onTimeUp = () => {} } = props;
  const renderCountDown = useCallback(
    () =>
      date ? (
        <CD renderer={(p) => renderer({ ...p, onTimeUp })} date={date} />
      ) : (
        <></>
      ),
    [date]
  );

  return <div>{renderCountDown()}</div>;
};

export default Countdown;
