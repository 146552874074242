import { Button, ButtonProps } from "antd";

import React from "react";

const CommonButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      style={{ ...props.style, border: "none" }}
      className={`common-btn ${props?.className}`}
      shape="round"
    />
  );
};

export default CommonButton;
