import { Col, Row } from "antd";
import React from "react";

const Header = () => {
  return (
    <Row
      justify="space-between"
      className="w-100"
      style={{ zIndex: 9999, height: 128, padding: 16, maxWidth: 960 }}
    >
      <Col
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        span={12}
      >
        <img
          style={{ height: 36, maxWidth: 164, objectFit: "contain" }}
          src="/hfest/logo_1.png"
          alt="logo"
        />
        <div style={{ color: "#ffe818", fontWeight: "bold", marginTop: 12 }}>
          GLAMPING{" "}
        </div>
        <div
          style={{
            color: "#ffe818",
            fontFamily: "Inconsolata",
            lineHeight: "6px",
          }}
        >
          MUSIC FESTIVAL
        </div>
      </Col>
      <Col
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
        span={12}
      >
        <div style={{ color: "#ffe818", fontWeight: "bold", fontSize: 28 }}>
          06.08.2022
        </div>
        <div
          style={{
            color: "#ffe818",
            lineHeight: "6px",
            fontWeight: "bold",
          }}
        >
          YEN SO PARK
        </div>
        <div
          style={{
            color: "#ffe818",
            fontFamily: "Inconsolata",
          }}
        >
          Gamuda City, HOANG MAI
        </div>
        <div
          style={{
            color: "#ffe818",
            fontFamily: "Inconsolata",
            lineHeight: "6px",
          }}
        >
          HANOI, VIETNAM
        </div>
      </Col>
    </Row>
  );
};

export default Header;
