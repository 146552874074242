import { Socket } from "socket.io-client";
import { Seat } from "../../models/type/seat";
import { store } from "../../rematch/store";
import { MapEvent } from "../events/map-event";

export const initMapHandlers = (socket: Socket) => {
  socket.on(MapEvent.DATA_CHANGED, (data) => {
    const userId = store.getState().auth.user.id;
    if (data?.length > 0) {
      const users = data?.filter((e: any) => !!e).map((e: any) => e[0]);
      if (!users.includes(userId)) {
        store.dispatch.booking.updateBookingState({
          selectedSeats: [],
        });
      }
      let unavailableSeatIds: any = [];

      data?.forEach((e: any) => {
        if (e[0] !== userId) {
          const seatIds = e[1].seats?.map((e: Seat) => e.seatId);
          unavailableSeatIds = unavailableSeatIds.concat(seatIds);
        } else {
          store.dispatch.booking.updateBookingState({
            releaseTime: e[1]?.releaseTime,
            selectedSeats: e[1]?.seats,
          });
        }
      });
      store.dispatch.booking.updateUnavailableSeatIds({
        unavailableSeatIds: unavailableSeatIds || [],
        bookingUsers: users || [],
      });
    } else {
      store.dispatch.booking.updateBookingState({
        selectedSeats: [],
        bookingDetail: {},
        unavailableSeatIds: [],
        bookingUsers: [],
      });
    }
  });

  socket.on(MapEvent.REFRESH_MAP, () => {
    store.dispatch.event.refreshEvent({});
  });
};
