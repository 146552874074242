export enum TicketType {
  STANDARD = "STANDARD",
  COMBO = "COMBO",
}

export interface IBookingSeat {
  id: string;
  seat: string;
  bookingId: string;
  eventId: string;
  userId: string;
  price: number;
  ticketType: TicketType;
  seatClass?: string;
}
