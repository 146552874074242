import { Row, Col, Form, Input, InputNumber, message, Button } from "antd";
import _, { isBuffer } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useLoading from "../../../../hooks/useLoading";
import { IGroupType } from "../../../../models/type/group-type";
import { Dispatch, RootState } from "../../../../rematch/store";
import { formatPrice } from "../../../../utils";
import { TicketType } from "../Stage/Stage";

const SelectZoneControl = (props: any) => {
  const { setDiscount } = props;
  const dispatch = useDispatch<Dispatch>();
  const [form] = Form.useForm();

  const bookingDetail = useSelector(
    (state: RootState) => state.booking.bookingDetail
  );
  const event = useSelector((state: RootState) => state.event.event);

  const user = useSelector((state: RootState) => state.auth.user);

  const createLoadingState = useLoading(
    (state: RootState) => state.loading.effects.booking.createBooking
  );

  const selectedZoneMap =
    useSelector((state: RootState) => state.booking.selectedZoneMap) || {};

  const updatePrice = (
    zone: IGroupType,
    ticketType: TicketType,
    quantity: number = 1
  ) => {
    if (!user) {
      dispatch.auth.setAuthModalVisible(true);
      return;
    }
    dispatch.booking.updateZonePrice({
      zone,
      quantity,
      ticketType,
    });
  };
  const applyCouponClick = async () => {
    const code = await form.getFieldValue("discountCode");
    if (!event) {
      return;
    }
    dispatch.booking.validateCoupon({
      eventId: event.id,
      code,
      cb: (res: any) => {
        setDiscount(res);
        if (res) {
          message.success("Áp dụng mã giảm giá thành công");
        } else {
          message.info("Mã giảm giá không tồn tại hoặc đã hết hạn");
        }
      },
    });
  };

  const renderQuantityControl = (zone: IGroupType) => {
    if (!zone.isOpen) {
      return (
        <div className="btn flex-center mt-1" style={{ color: "black" }}>
          MỞ BÁN TẠI CỔNG
        </div>
      );
    }
    const remainSeat =
      zone.totalSeat - zone.bookedSeat >= 0
        ? zone.totalSeat - zone.bookedSeat
        : 0;
    if (zone.redirectUrl) {
      return (
        <a
          target={"_blank"}
          className="btn flex-center mt-1"
          style={{ color: "black" }}
          href={zone.redirectUrl}
          rel="noreferrer"
        >
          Đặt mua
        </a>
      );
    }
    return (
      <>
        <div
          className="quantity-control"
          style={{ position: "relative", marginTop: 16 }}
        >
          {remainSeat <= 0 ? (
            <img
              style={{
                height: 45,
                position: "absolute",
                right: -12,
                bottom: 0,
              }}
              src="/hfest/sold_out.png"
              alt="sold-out"
            />
          ) : (
            <>
              <InputNumber
                controls={false}
                onClick={() => {
                  if (!user) {
                    dispatch.auth.setAuthModalVisible(true);
                    return;
                  }
                }}
                min={0}
                max={remainSeat}
                className="quantity-control-input"
                value={Math.min(
                  selectedZoneMap[zone.seatClass]?.quantity || 0,
                  remainSeat
                )}
                onChange={(value) => {
                  updatePrice(
                    zone,
                    selectedZoneMap[zone.seatClass].ticketType ||
                      TicketType.STANDARD,
                    +value
                  );
                }}
              />
              <Button
                className="quantity-btn"
                onClick={() => {
                  updatePrice(
                    zone,
                    selectedZoneMap[zone.seatClass].ticketType ||
                      TicketType.STANDARD,
                    Math.min(
                      (selectedZoneMap[zone.seatClass]?.quantity || 0) + 1,
                      remainSeat
                    )
                  );
                }}
              >
                +
              </Button>
              <Button
                className="quantity-btn"
                onClick={() => {
                  updatePrice(
                    zone,
                    selectedZoneMap[zone.seatClass].ticketType ||
                      TicketType.STANDARD,
                    (selectedZoneMap[zone.seatClass]?.quantity || 0) >= 1
                      ? (selectedZoneMap[zone.seatClass]?.quantity || 0) - 1
                      : 0
                  );
                }}
              >
                -
              </Button>
            </>
          )}
        </div>
      </>
    );
  };
  const renderTicketBox = () => {
    const sortedGroupTypes: any = _.sortBy(event?.groupTypes, "order", "asc");

    const info = sortedGroupTypes?.map((zone: IGroupType, index: number) => {
      const remainSeat =
        zone.totalSeat - zone.bookedSeat >= 0
          ? zone.totalSeat - zone.bookedSeat
          : 0;
      return (
        <Col
          md={8}
          sm={12}
          xs={24}
          className="flex-center"
          style={{ alignItems: "unset" }}
        >
          <div className="price-box">
            {zone?.isNew && (
              <img
                style={{ width: 36, position: "absolute", right: 36, top: 4 }}
                src="/hfest/new.png"
                alt="new"
              />
            )}
            <div
              style={{
                opacity: remainSeat > 0 ? 1 : 0.5,
              }}
            >
              <div className="title">{zone.title}</div>
              <div className="seat-class">
                {zone.seatClass.startsWith("VIP") ||
                zone.seatClass.startsWith("NFT")
                  ? "HẠNG VIP"
                  : "HẠNG GENERAL ADMISSION"}
              </div>
              <div className="price">
                {!zone.redirectUrl ? formatPrice(zone?.price) : `50 BUSD/USDT`}
              </div>
              <div className="description">
                <span>{zone?.description}</span>
              </div>
            </div>
            {renderQuantityControl(zone)}
          </div>
        </Col>
      );
    });
    return (
      <div style={{ height: "100%" }} className="flex-hcenter">
        <Row
          style={{
            width: "100%",
            maxWidth: 1280,
          }}
          className="mt-2"
          justify="center"
          gutter={[32, 32]}
        >
          {info}
        </Row>

        <div className="px-1 mt-2 w-100">
          <div
            style={{
              color: "#ffe818",
              fontStyle: "italic",
              marginTop: 24,
              marginBottom: 12,
              padding: 24,
              backgroundColor: "rgba(0,0,0,0.5)",
              border: "solid 1px #ffe818",
            }}
          >
            <b>Lưu ý:</b> <br />- Giá vé dành cho một người hoặc nhóm người
            <br />- Vé đã mua không hoàn, không huỷ
            <br />- Chương trình dành cho khách từ 16 tuổi trở lên <br />- Không
            mang đồ ăn thức uống bên ngoài vào khu vực diễn ra chương trình
            <br />- Không được sử dụng các chất kích thích, các chất dễ gây cháy
            nổ <br />- Vui lòng tải QR code về máy để dễ dàng checkin tại sự
            kiện
          </div>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: "100%" }}
            autoComplete="off"
          >
            <div className="customer-info">Thông tin khách hàng</div>

            <Form.Item
              initialValue={user?.name}
              label={"Họ và tên"}
              name="name"
              rules={[{ required: true, message: "Vui lòng nhập họ và tên" }]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              initialValue={user?.phone}
              label={"Số điện thoại"}
              name="phone"
              rules={[
                { required: true, message: "Vui lòng nhập số điện thoại" },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              initialValue={user?.email}
              label={"Email"}
              name="email"
              rules={[
                { required: true, message: "Vui lòng nhập số điện thoại" },
                {
                  type: "email",
                  message: "Vui lòng nhập đúng định dạng email",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Row>
              <Col flex={1}>
                <Form.Item label={"Mã giảm giá"} name="discountCode">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type="primary"
                  style={{
                    marginTop: 40,
                    backgroundColor: "#ffe818",
                    color: "black",
                    border: "none",
                    fontWeight: "bold",
                  }}
                  onClick={applyCouponClick}
                >
                  Áp dụng
                </Button>
              </Col>
            </Row>
          </Form>
          <div className="dvd"></div>
          <Row justify="space-between" className="w-100">
            <Col>
              <h3 className="price-title" style={{ color: "#ffe818" }}>
                Thành tiền
              </h3>
            </Col>

            <Col className="flex-hcenter">
              {bookingDetail.subPrice !== bookingDetail.totalPrice && (
                <b
                  style={{
                    color: "#ffe818",
                    fontSize: 24,
                    textDecoration: "line-through",
                  }}
                >
                  {formatPrice(bookingDetail.subPrice || 0)}
                </b>
              )}
              <b style={{ color: "#ffe818", fontSize: 24 }}>
                {formatPrice(bookingDetail.totalPrice || 0)}
              </b>
            </Col>
          </Row>

          <Button
            className="next-btn mt-1"
            style={{ width: "100%" }}
            loading={createLoadingState.loading}
            onClick={onNextClick}
          >
            Tiếp tục
          </Button>
        </div>
      </div>
    );
  };
  const navigate = useNavigate();
  // if (!selectedZones?.length) {
  //   return <></>;
  // }

  const onNextClick = async () => {
    if (bookingDetail?.subPrice === 0) {
      message.error("Vui lòng chọn chỗ");
      return;
    }
    dispatch.booking.createBooking({
      ...bookingDetail,
      ...(await form.validateFields()),
      cb: (res: any) => {
        navigate(`/hayfest/checkout/${res.id}`);
      },
    });
  };
  return (
    <Row style={{ width: "100%", marginBottom: 64 }} justify="center">
      <Col>
        <Row style={{ flexDirection: "column" }}>
          <div className="customer-info flex-center" style={{ fontSize: 24 }}>
            Hạng vé
          </div>
          <div>{renderTicketBox()}</div>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectZoneControl;
