import { Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../rematch/store";
import Marquee from "react-fast-marquee";

const Banner = () => {
  const dimension = useSelector((state: RootState) => state.dimension);
  const event = useSelector((state: RootState) => state.event.event);

  const renderLineUp = () => {
    let res = [];
    for (let i = 0; i < Math.max(event?.lineups?.length, 20); i++) {
      const e = event?.lineups[i % event?.lineups?.length];
      res.push(
        <>
          <div
            style={{
              width: 128,
              height: 128,
              minWidth: 128,
              borderRadius: 128,
              backgroundColor: "#ffe818",
              zIndex: 99,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <span style={{ zIndex: 99 }}> COMING SOON</span>

            <img
              style={{
                position: "absolute",
                width: 32,
                height: 32,
                objectFit: "contain",
              }}
              src={`/hfest/m_icon_${(i % 4) + 1}.png`}
              alt="icon"
            />
          </div>
          <div
            style={{
              width: 128,
              height: 128,
              minWidth: 128,
              borderRadius: 128,
              backgroundColor: "#ffe818",
              zIndex: 99,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              color: "white",
              fontWeight: "bold",
              overflow: "hidden",
              border: "solid 2px #ffe818",
            }}
          >
            <img
              src={e?.smallImg}
              alt="lineup"
              style={{ objectFit: "cover", height: "100%" }}
            />
          </div>
        </>
      );
    }
    return res;
  };

  return (
    <Row className="banner-container">
      <div className="flex-center" style={{ position: "relative" }}>
        <img
          src={
            dimension?.isMobile ? "/hfest/m_banner.jpg" : "/hfest/banner.jpg"
          }
          alt="banner"
        />
      </div>
      <div
        className="coming"
        style={{
          position: "absolute",
          bottom: 32,
          zIndex: 99,
          width: dimension.screenWidth,
          display: "flex",
        }}
      >
        <Marquee gradient={false} direction="right">
          {renderLineUp()}
        </Marquee>
      </div>
      <div
        style={{ height: 96, backgroundColor: "#189ED6", width: "100%" }}
      ></div>
    </Row>
  );
};

export default Banner;
