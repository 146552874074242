import { createModel } from "@rematch/core";
import { RootModel } from ".";
import {
  createEvent,
  getEvents,
  getEvent,
  updateEvent,
  getEventByProgramId,
} from "../apis/event";
import { IEvent } from "./type/event";

type EventState = {
  events: [];
  totalEvent: number;
  event: IEvent | null;
  seatMap: any;
};

export const event = createModel<RootModel>()({
  state: {
    events: [],
    totalEvent: 0,
    event: null,
    seatMap: {},
  } as EventState,
  reducers: {
    createEventSuccess(state, payload: any) {
      return { ...state, event: payload };
    },
    getEventsSuccess(state, payload: any) {
      return {
        ...state,
        events: payload.rows || [],
        totalEvent: payload.count || 0,
      };
    },
    getEventSuccess(state, payload: any) {
      return { ...state, event: payload };
    },
    updateSeatMap(state, payload: any) {
      return { ...state, seatMap: payload };
    },
  },
  effects: (dispatch) => ({
    async createEvent(payload: any, state) {
      const res = await createEvent(payload);
      if (res) {
        dispatch.event.createEventSuccess(res.data?.result);
      }
    },
    async updateEvent(payload: any, state) {
      const res = await updateEvent(payload.id, payload.data);
      if (res) {
        dispatch.event.getEvent({ id: payload.id });
      }
    },
    async getEvents(
      payload: {
        query?: any;
      },
      state
    ) {
      const res = await getEvents(payload.query);
      dispatch.event.getEventsSuccess(res.data?.result);
    },
    async getEvent(payload: any, state) {
      const res = await getEvent(payload.id);
      if (res) {
        dispatch.booking.setUpStage(res.data?.result);
        dispatch.event.getEventSuccess(res.data?.result);
      }
      payload?.cb && payload?.cb();
    },
    async getEventByProgramId(payload: any, state) {
      const res = await getEventByProgramId(payload.programId);
      if (res) {
        dispatch.booking.setUpStage(res.data?.result);
        dispatch.event.getEventSuccess(res.data?.result);
      }
      payload?.cb && payload?.cb();
    },
    async refreshEvent(payload: any, state) {
      if (state?.event?.event?.id) {
        const res = await getEvent(state?.event?.event?.id);
        if (res) {
          dispatch.event.getEventSuccess(res.data?.result);
        }
      }
    },
  }),
});
