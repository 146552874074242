import { DefaultEventsMap } from "@socket.io/component-emitter";
import { io, Socket } from "socket.io-client";
import * as BookingHandlers from "../handlers/booking-handlers";
import { BookingEvent } from "../events/booking-event";
import { configs } from "../../services/config";
import { store } from "../../rematch/store";

let instance: Socket<DefaultEventsMap, DefaultEventsMap> | null;

export const initBookingSocket = (id: string) => {
  const socket = getBookingSocketInstance(id);
  BookingHandlers.initBookingHandlers(socket);

  socket.on("connect", () => {
    console.log("connected");
  });

  socket.on("connect_error", (err: any) => {
    console.log("socker error:", err.message);
  });

  return socket;
};

export const getBookingSocketInstance = (id: string) => {
  if (!instance) {
    instance = io(`${configs.socketURL}/booking/${id}`, {
      reconnectionDelayMax: 10000,
      auth: {
        token: store.getState().auth.tokens?.access.token,
      },
    });
  }
  return instance;
};

export const emitEvent = (id: string, event: string, data: any) => {
  instance?.emit(event, data);
};

export const disconnect = (id: string) => {
  instance?.disconnect();
  instance = null;
};

export const BookingEventEmitter = {
  selectSeats: (id: string, seats: any) => {
    emitEvent(id, BookingEvent.BOOKING_STATUS_CHANGED, {
      seats,
    });
  },
};
