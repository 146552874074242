import { Seat, SeatStatus } from "../../../models/type/seat";
import saltus from "../../../maps/saltus.json";
import pollux from "../../../maps/pollux.json";
import { ISeatType } from "../../../models/type/seat-type";

export const getBgColor = ({
  isUnavailable,
  isSelected,
  isDisabled,
  color,
}: any) => {
  if (isUnavailable) {
    return "red";
  }

  if (isSelected) {
    return "yellow";
  }

  if (isDisabled) {
    return "rgba(255,255,255,0.7)";
  }

  return color || "#3c6f72";
};

export const getSeatProps = (props: {
  block: string;
  seatRow: string;
  position: number;
  seatClass: string;
  priceMap: { [key: string]: number };
  selectedSeats: Seat[];
  unavailableSeatIds: string[];
  bookedSeatIds: string[];
}) => {
  const { block, seatRow, position, priceMap, seatClass } = props;
  const seatId = `${seatRow}${position}`;

  const seat: Seat = {
    block: block,
    row: seatRow,
    position: position,
    seatId,
    seatClass,
    status: SeatStatus.AVAILABLE,
    price: priceMap[seatClass],
  };

  const isSelected = !!props.selectedSeats?.find(
    (e: Seat) => e.seatId === seatId
  );
  const isBooked = props.bookedSeatIds.includes(seatId);
  const isUnavailable = props.unavailableSeatIds.includes(seatId);
  const isDisabled = isBooked || isUnavailable;
  const backgroundColor = getBgColor({
    isSelected,
    isDisabled,
    isUnavailable,
    color: "#d7a578",
  });

  return {
    seat,
    isBooked,
    isUnavailable,
    isSelected,
    backgroundColor,
    isDisabled: isBooked || isUnavailable,
  };
};

export const seatColors: { [key: string]: string } = {
  STAR: "#aa4e36",
  MOON: "#3c6f72",
  SUN: "#d7a578",
  GIFT: "#8e7754",
  UNIVERSE: "#bd7d4c",
  ROOTY: "#6199bb",
  WOODY: "#98b13f",
  BRANCHY: "#c45a3b",
  LEAFY: "#1ca454",
};

export const sortedSeatClassGroup = ["ROOTY", "WOODY", "BRANCHY", "LEAFY"];
export const sortedSeatClass = ["GIFT", "STAR", "MOON", "UNIVERSE", "SUN"];

export const checkEmptySeat = (seat: Seat[]) => {};

export const groupByBlock = (seats: Seat[]) => {
  const grouped = seats.reduce((acc: any, seat: Seat) => {
    if (!acc[seat.block]) {
      acc[seat.block] = [];
    }
    acc[seat.block].push(seat);
    return acc;
  }, {});
  return grouped;
};

export const groupByRow = (seats: Seat[]) => {
  const grouped = seats.reduce((acc: any, seat: Seat) => {
    if (!acc[seat.row]) {
      acc[seat.row] = [];
    }
    acc[seat.row].push(seat);
    return acc;
  }, {});
  return grouped;
};

export const groupByBlockAndRow = (seats: Seat[]) => {
  const grouped = seats.reduce((acc: any, seat: Seat) => {
    if (!acc[seat.block]) {
      acc[seat.block] = {};
    }
    if (!acc[seat.block][seat.row]) {
      acc[seat.block][seat.row] = [];
    }
    acc[seat.block][seat.row].push(seat);
    return acc;
  }, {});
  return grouped;
};

export const checkLeftEmptySeat = (
  selectedBlocks: any,
  unavailableSeatIds: string[],
  stageId: string,
  cb?: any
) => {
  const seatMapPlan: any = stageId === "10000" ? saltus : pollux;
  const listBlocks = Object.keys(selectedBlocks || {});
  let isOk = true;
  for (let index = 0; index < listBlocks.length; index++) {
    const block = listBlocks[index];
    const rows = Object.keys(selectedBlocks[block] || {});
    for (let rIndex = 0; rIndex < rows.length; rIndex++) {
      const row = rows[rIndex];
      const rowInfo = selectedBlocks[block][row];
      const isExistSunClass = rowInfo.some((seat: Seat) => {
        return seat.seatClass === "SUN";
      });
      if (isExistSunClass) {
        break;
      }
      const sortedRowInfo = rowInfo.sort(
        (a: any, b: any) => a.position - b.position
      );
      const sortedRowInfoIds = sortedRowInfo.map((e: any) => e.seatId);
      const blockRowConfig = seatMapPlan[block].seatPlans[row];
      const firstSeat = sortedRowInfo[0];
      const lastSeat = sortedRowInfo[sortedRowInfo.length - 1];
      const lockedSeats = getUnavailableSeatIdsFromBlockAndRow(
        seatMapPlan,
        block,
        row,
        unavailableSeatIds
      );
      let centerCheck = 0;

      for (let i = firstSeat.position + 1; i < lastSeat.position; i++) {
        if (
          !sortedRowInfoIds.includes(`${row}${i}`) &&
          !lockedSeats.includes(`${row}${i}`)
        ) {
          centerCheck++;
        }
        if (centerCheck === 1 && i === lastSeat.position - 1) {
          isOk = false;
          break;
        }
        if (centerCheck === 2) {
          break;
        }
      }

      let rightCheck = 0;
      let leftCheck = 0;

      for (
        let i = lastSeat.position + 1;
        i <= blockRowConfig[blockRowConfig.length - 1];
        i++
      ) {
        if (!lockedSeats.includes(`${row}${i}`)) {
          rightCheck++;
        } else {
          if (rightCheck === 1) {
            isOk = false;
          }
          break;
        }
        if (rightCheck === 2) {
          break;
        }
      }

      for (let i = firstSeat.position - 1; i > blockRowConfig[0]; i--) {
        if (!lockedSeats.includes(`${row}${i}`)) {
          leftCheck++;
        } else {
          if (leftCheck === 1) {
            isOk = false;
          }
          break;
        }
        if (leftCheck === 2) {
          break;
        }
      }
    }
  }
  cb(isOk);
};

export const getUnavailableSeatIdsFromBlockAndRow = (
  map: any,
  block: string,
  row: string,
  unavailableSeatIds: string[]
) => {
  const blockRows = map[block];
  const rowSeats = blockRows?.seatPlans[row];
  let lockedSeats: any = [];
  rowSeats.forEach((e: any) => {
    if (unavailableSeatIds.includes(`${row}${e}`)) {
      lockedSeats.push(`${row}${e}`);
    }
  });
  return lockedSeats;
};

export const getDefaultSeatQuantity = (seatClass: string) => {
  switch (seatClass) {
    case "SUN":
      return 1;
    default:
      return 1;
  }
};
