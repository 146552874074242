import { stringify } from "qs";
import request from "../services/request";

export const createEvent = (payload: any) => {
  return request.post("/v1/event", payload);
};

export const updateEvent = (id: string, payload: any) => {
  return request.patch(`/v1/event/${id}`, payload);
};

export const getEvents = (query: any) => {
  return request.get(`/v1/event/incoming?${stringify(query)}`);
};

export const getEvent = (id: string) => {
  return request.get(`/v1/event/${id}`);
};
export const getEventByProgramId = (id: string) => {
  return request.get(`/v1/event/by-program/${id}`);
};

export const deleteEvent = (id: string) => {
  return request.delete(`/v1/event/${id}`);
};
