/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../../rematch/store";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./Stage.scss";
import { DiscountType } from "../../../../models/discount";

export enum TicketType {
  STANDARD = "STANDARD",
  COMBO = "COMBO",
}

const Stage = (props: any) => {
  const { event, discount } = props;

  const dispatch = useDispatch<Dispatch>();

  const dimension = useSelector((state: RootState) => state.dimension);

  const selectedZoneMap = useSelector(
    (state: RootState) => state.booking.selectedZoneMap
  );

  useEffect(() => {
    let totalPrice = 0;
    let subPrice = 0;
    Array.from(Object.values(selectedZoneMap)).forEach((acc: any) => {
      totalPrice = totalPrice + acc.selectedPrice * acc.quantity;
    });
    subPrice = totalPrice;
    let discountAmount = 0;
    if (discount) {
      if (discount?.discountType === DiscountType.AMOUNT) {
        discountAmount = discount.amountDiscount;
      } else {
        discountAmount = (totalPrice * discount.percentageDiscount) / 100;
      }
      totalPrice = Math.round((totalPrice - discountAmount) * 100) / 100;
      totalPrice = totalPrice > 0 ? totalPrice : 0;
    }
    dispatch.booking.updateBookingDetail({
      totalPrice,
      eventId: event?.id,
      blockInfo: Array.from(Object.values(selectedZoneMap)),
      subPrice,
    });
    return () => {};
  }, [event, selectedZoneMap, dispatch.booking, discount]);

  const bookingUsers = useSelector(
    (state: RootState) => state.booking.bookingUsers
  );

  const [stageWidth, setWidth] = useState(0);

  const panZoomRef = useRef<any>(null);
  useEffect(() => {
    const stage = document.querySelector(".stage");

    if (stage) {
      const resizeObserver = new ResizeObserver((entries) => {
        const width = entries[0].contentRect.width;
        setWidth(width);
        panZoomRef?.current?.centerView(1);
      });

      resizeObserver.observe(stage);
    }
  }, [document.querySelector(".stage")]);

  const renderStage = () => {
    return (
      <img
        alt="event"
        style={{ width: "100%", height: "100%" }}
        src={event?.seatPlanImg}
      ></img>
    );
  };

  return (
    <Row style={{ width: "100%", maxWidth: 1360 }} className="flex-center">
      <div
        className="flex-center"
        style={{ flexDirection: "column", width: "100%" }}
      >
        <div className="customer-info" style={{ fontSize: 24 }}>
          Sơ đồ chỗ ngồi
        </div>
        <span className="text flex-center">
          {bookingUsers?.length
            ? `Có ${bookingUsers?.length} người xem đang chọn đặt chỗ cùng bạn`
            : ""}
        </span>
      </div>

      <Col span={24} className={dimension.isMobile ? "p-0.25" : "p-1"}>
        <div className="stage">
          {stageWidth && (
            <TransformWrapper
              ref={panZoomRef}
              minScale={1}
              centerOnInit
              initialScale={1}
            >
              <TransformComponent
                wrapperStyle={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {renderStage()}
              </TransformComponent>
            </TransformWrapper>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Stage;
