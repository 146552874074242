import { Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../rematch/store";

const Layout = () => {
  const dimension = useSelector((state: RootState) => state.dimension);

  const event = useSelector((state: RootState) => state.event.event);

  return (
    <Row
      className="intro"
      style={{
        width: dimension.screenWidth,
        height: "auto",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Row
        style={{
          maxWidth: 640,
          marginTop: 32,
          marginBottom: 32,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img style={{ width: "100%" }} alt="logo" src={event?.seatPlanImg} />
      </Row>
    </Row>
  );
};

export default Layout;
