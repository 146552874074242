import React, { useEffect, useState } from "react";
import "./ListEvent.scss";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "../../rematch/store";
import { Col, Row, Spin } from "antd";

const ListEvent = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = new URLSearchParams(window.location.search);
  const events = useSelector((state: RootState) => state.event.events);
  const getEventLoading = useSelector(
    (state: RootState) => state.loading.effects.event.getEvents
  );
  const getProgramLoading = useSelector(
    (state: RootState) => state.loading.effects.program.getPrograms
  );
  const [currentProgram, setCurrentProgram] = useState<any>(null);
  const program = params.get("program");

  const programs = useSelector((state: RootState) => state.program.programs);

  useEffect(() => {
    dispatch.program.getPrograms({});
    return () => {};
  }, []);

  useEffect(() => {
    const pg = programs.find((e: any) => e.id === program);
    setCurrentProgram(pg);
    return () => {};
  }, [program, programs]);

  useEffect(() => {
    dispatch.event.getEvents({
      query: {
        programId: program,
      },
    });
  }, [program]);

  const navigate = useNavigate();

  return (
    <div
      style={{ marginBottom: 32, flexDirection: "column" }}
      className="flex-center"
    >
      <div className="flex-center" style={{ flexDirection: "column" }}>
        <h1 className="text"> Đêm diễn sắp diễn ra tại</h1>
        <h1 className="section-title">
          {!getProgramLoading.loading && (
            <img
              src={currentProgram?.darkLogo}
              alt="logo"
              style={{ height: 80 }}
            />
          )}
        </h1>
      </div>
      {getEventLoading.loading ? (
        <Spin spinning />
      ) : events.length > 0 ? (
        <div style={{ maxWidth: 1280 }}>
          <Row className="p-2" gutter={[32, 32]} justify="center">
            {events.map((item: any, index) => {
              return (
                <Col
                  md={8}
                  sm={24}
                  xs={24}
                  onClick={() => navigate(`/event/${item.id}`)}
                  className="event-card"
                >
                  <div className="event-banner flex-center">
                    <img src={item?.posterImg} alt="" />
                    <div className="bottom">
                      <Button
                        className="booking-btn"
                        onClick={() => navigate(`/event/${item.id}`)}
                      >
                        Đặt chỗ
                      </Button>
                      <div className="event-banner-overlay"></div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div className="flex-center">
          <h3 className="text mt-2">Hiện chưa có sự kiện nào</h3>
        </div>
      )}
    </div>
  );
};

export default ListEvent;
