import {
  Radio,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Button,
  Tag,
} from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../components/Button/Button";
import useLoading from "../../../hooks/useLoading";
import { IGroupType } from "../../../models/type/group-type";
import { Dispatch, RootState } from "../../../rematch/store";
import { formatPrice } from "../../../utils";
import { TicketType } from "../Stage/Stage";

const SelectZoneControl = (props: any) => {
  const dispatch = useDispatch<Dispatch>();
  const { setDiscount } = props;
  const [form] = Form.useForm();

  const bookingDetail = useSelector(
    (state: RootState) => state.booking.bookingDetail
  );
  const event = useSelector((state: RootState) => state.event.event);

  const user = useSelector((state: RootState) => state.auth.user);

  const createLoadingState = useLoading(
    (state: RootState) => state.loading.effects.booking.createBooking
  );

  const selectedZoneMap =
    useSelector((state: RootState) => state.booking.selectedZoneMap) || {};

  const updatePrice = (
    zone: IGroupType,
    ticketType: TicketType,
    quantity: number = 1
  ) => {
    if (!user) {
      dispatch.auth.setAuthModalVisible(true);
      return;
    }
    dispatch.booking.updateZonePrice({
      zone,
      quantity,
      ticketType,
    });
  };

  const applyCouponClick = async () => {
    const code = await form.getFieldValue("discountCode");
    if (!event) {
      return;
    }
    dispatch.booking.validateCoupon({
      eventId: event.id,
      code,
      cb: (res: any) => {
        setDiscount(res);
        if (res) {
          message.success("Áp dụng mã giảm giá thành công");
        } else {
          message.info("Mã giảm giá không tồn tại hoặc đã hết hạn");
        }
      },
    });
  };

  const renderTicketBox = () => {
    const sortedGroupTypes: any = _.sortBy(event?.groupTypes, "order", "asc");

    const info = sortedGroupTypes?.map((zone: IGroupType, index: number) => {
      const hasCombo = !!zone?.comboPrice;
      const remainSeat =
        zone.totalSeat - zone.bookedSeat >= 0
          ? zone.totalSeat - zone.bookedSeat
          : 0;
      return (
        <Row className="ticket-box mt-1" key={index}>
          <Col span={24}>
            <Row>
              <Col>
                <b>Hạng: {zone.title} </b>
              </Col>
              <Col>
                {zone?.isEb && (
                  <Tag
                    style={{
                      marginLeft: 2,
                      color: "#cf1322",
                      marginBottom: 2,
                      fontWeight: 500,
                    }}
                    color={"red"}
                  >
                    Mở bán sớm
                  </Tag>
                )}
                {zone?.isNew && (
                  <Tag
                    style={{
                      marginLeft: 2,
                      color: "#d48806",
                      marginBottom: 2,
                      fontWeight: 500,
                    }}
                    color={"gold"}
                  >
                    Mới
                  </Tag>
                )}
                {remainSeat <= 0 ? "(Hết chỗ)" : ""}
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="ml-1">Số lượng</div>
            <InputNumber
              className="common-input"
              onClick={() => {
                if (!user) {
                  dispatch.auth.setAuthModalVisible(true);
                  return;
                }
              }}
              disabled={remainSeat <= 0}
              min={0}
              max={remainSeat}
              defaultValue={0}
              value={selectedZoneMap[zone.seatClass].quantity}
              style={{ marginLeft: 12 }}
              controls={false}
              onChange={(value) => {
                updatePrice(
                  zone,
                  selectedZoneMap[zone.seatClass].ticketType ||
                    TicketType.STANDARD,
                  value
                );
              }}
            />
            {hasCombo && (
              <Radio.Group className="ml-1" defaultValue={0}>
                <Radio
                  value={0}
                  onClick={() =>
                    updatePrice(
                      zone,
                      TicketType.COMBO,
                      selectedZoneMap[zone.seatClass].quantity || 1
                    )
                  }
                >
                  Combo
                </Radio>
                <Radio
                  value={1}
                  onClick={() =>
                    updatePrice(
                      zone,
                      TicketType.STANDARD,
                      selectedZoneMap[zone.seatClass].quantity || 1
                    )
                  }
                >
                  Show only
                </Radio>
              </Radio.Group>
            )}
          </Col>
          <Col span={24}>
            <div className="ticket-box-content ml-1">
              Giá:{" "}
              <b>
                {formatPrice(selectedZoneMap[zone.seatClass].selectedPrice)}x{" "}
                {selectedZoneMap[zone.seatClass]?.quantity}
              </b>
            </div>
          </Col>
        </Row>
      );
    });
    return (
      <div style={{ height: "100%" }}>
        {info}
        <Form
          form={form}
          className="common-form"
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 540 }}
          autoComplete="off"
        >
          <Form.Item
            initialValue={user?.name}
            label={"Họ và tên"}
            name="name"
            rules={[{ required: true, message: "Vui lòng nhập họ và tên" }]}
          >
            <Input className="common-input"></Input>
          </Form.Item>
          <Form.Item
            initialValue={user?.phone}
            label={"Số điện thoại"}
            name="phone"
            rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}
          >
            <Input className="common-input"></Input>
          </Form.Item>
          <Form.Item
            initialValue={user?.email}
            label={"Email"}
            name="email"
            rules={[
              { required: true, message: "Vui lòng nhập số điện thoại" },
              {
                type: "email",
                message: "Vui lòng nhập đúng định dạng email",
              },
            ]}
          >
            <Input className="common-input"></Input>
          </Form.Item>
          <Row className="w-100">
            <Col span={18}>
              <Form.Item label={"Mã giảm giá"} name="discountCode">
                <Input className="common-input"></Input>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  marginTop: 41,
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  fontSize: 12,
                  height: 30,
                }}
                onClick={applyCouponClick}
              >
                Áp dụng
              </Button>
            </Col>
          </Row>
        </Form>
        <Row justify="space-between" className="w-100">
          <Col>
            <h3 className="price-title" style={{ color: "white" }}>
              Thành tiền
            </h3>
          </Col>

          <Col className="flex-hcenter">
            {bookingDetail.subPrice &&
              bookingDetail.subPrice !== bookingDetail.totalPrice && (
                <b
                  style={{
                    color: "white",
                    fontSize: 24,
                    textDecoration: "line-through",
                  }}
                >
                  {formatPrice(bookingDetail.subPrice || 0)}
                </b>
              )}
            <b style={{ color: "white", fontSize: 24 }}>
              {formatPrice(bookingDetail.totalPrice || 0)}
            </b>
          </Col>
        </Row>
      </div>
    );
  };
  const navigate = useNavigate();
  // if (!selectedZones?.length) {
  //   return <></>;
  // }

  const onNextClick = async () => {
    if (bookingDetail.totalPrice === 0) {
      message.error("Vui lòng chọn chỗ");
      return;
    }
    dispatch.booking.createBooking({
      ...bookingDetail,
      ...(await form.validateFields()),
      cb: (res: any) => {
        navigate(`/checkout/${res.id}`);
      },
    });
  };
  return (
    <Row style={{ width: "100%" }} justify="center" className="mb-3 px-2">
      <Col>
        <Row style={{ flexDirection: "column" }}>
          <h1 className="section-title flex-center mt-2">Thông tin đặt chỗ</h1>
          <div className="mt-1">
            {renderTicketBox()}
            <CommonButton
              style={{ width: "100%" }}
              loading={createLoadingState.loading}
              className="my-2"
              onClick={onNextClick}
            >
              Tiếp tục
            </CommonButton>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectZoneControl;
