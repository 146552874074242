import { stringify } from "qs";
import request from "../services/request";

export const getPrograms = (query: any) => {
  return request.get(
    `/v1/program/active?${stringify({
      ...query,
      workspace: "BROS",
    })}`
  );
};

export const getProgram = (id: any, query: any) => {
  return request.get(`/v1/program/${id}?${stringify(query)}`);
};
export const createProgram = (payload: any) => {
  return request.post(`/v1/program`, payload);
};
export const updateProgram = (id: string, payload: any) => {
  return request.patch(`/v1/program/${id}`, payload);
};

export const deleteProgram = (id: string) => {
  return request.delete(`/v1/program/${id}`);
};
