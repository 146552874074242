/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Table, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import "./EventDetail.scss";

import Stage from "./Stage";
import {
  ClockCircleOutlined,
  PushpinOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../rematch/store";
import { useParams } from "react-router-dom";
import moment from "moment";
import * as Socket from "../../sockets/core/event";
import { formatPrice } from "../../utils";
import { Box } from "../../components";
import SelectSeatControl from "./SelectSeatControl";
import { TicketPlan } from "../../models/type/event";
import { seatColors } from "./Stage/helper";
import _ from "lodash";
import SelectZoneControl from "./SelectZoneControl";

const EventDetail = () => {
  const dispatch = useDispatch<Dispatch>();
  const { id } = useParams();
  const bookedSeatIds = useSelector(
    (state: RootState) => state.booking.bookedSeatIds
  );
  const [discount, setDiscount] = useState(null);
  const event = useSelector((state: RootState) => state.event.event);

  const [localLoading, setLocalLoading] = useState(true);

  const isMobile = useSelector((state: RootState) => state.dimension.isMobile);

  useEffect(() => {
    dispatch.event.getEvent({
      id,
      cb: () => {
        setLocalLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    Socket.initMapSocket(id!);
    return () => {
      Socket.disconnect(id!);
      dispatch.booking.updateUnavailableSeatIds({
        unavailableSeatIds: [],
        bookingUsers: [],
      });
    };
  }, [id]);

  const renderTicketInfo = () => {
    if (!event?.seatTypes && !event?.groupTypes) return <></>;
    const data = _.uniqBy(
      event[event?.ticketPlan === TicketPlan.SEAT ? "seatTypes" : "groupTypes"],
      "seatClass"
    );
    const sortedData: any = _.sortBy(data, "order");
    // const seatClass =
    //   event?.ticketPlan === TicketPlan.SEAT
    //     ? sortedSeatClass
    //     : sortedSeatClassGroup;
    // seatClass.forEach((e) => {
    //   const item = data.find((i) => i.seatClass === e);
    //   if (item) {
    //     sortedData.push(item);
    //   }
    // });

    const columns: any = [
      {
        title: "Hạng",
        dataIndex: "seatClass",
        key: "seatClass",
        render: (seatClass: string, record: any) => {
          return (
            <div style={{ display: "flex" }}>
              <div
                className={`seat-code ${seatClass?.toLowerCase()}`}
                style={{
                  backgroundColor: record?.color || seatColors[seatClass],
                }}
              ></div>
              <Row>
                <Col>
                  <b className="text">{record?.title} </b>
                </Col>
                <Col>
                  {record?.isEb && (
                    <Tag
                      style={{ marginLeft: 2, fontWeight: 500 }}
                      color={"red"}
                    >
                      Mở bán sớm
                    </Tag>
                  )}
                  {record?.isNew && (
                    <Tag
                      style={{ marginLeft: 2, fontWeight: 500 }}
                      color={"gold"}
                    >
                      Mới
                    </Tag>
                  )}
                </Col>
              </Row>
            </div>
          );
        },
      },
      {
        title: "Giá tiền",
        dataIndex: "price",
        key: "price",
        render: (price: number) => {
          return formatPrice(price);
        },
      },
    ];

    const isHaveComboPrice = sortedData.some((e: any) => !!e.comboPrice);
    if (isHaveComboPrice) {
      columns.push({
        title: "Giá combo",
        dataIndex: "comboPrice",
        key: "comboPrice",
        render: (price: number) => {
          return !price ? "N/A" : formatPrice(price);
        },
      });
    }

    columns.push({
      title: "Ghi chú",
      dataIndex: "description",
      key: "description",
    });

    return (
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={sortedData}
      ></Table>
    );

    // return _.uniqBy(
    //   event[event?.ticketPlan === TicketPlan.SEAT ? "seatTypes" : "groupTypes"],
    //   "seatClass"
    // ).map((seat: any) => {
    //   console.log(seat);
    //   return (
    //     <Col md={12} sm={24}>
    //       <div
    //         style={{ flexDirection: "column", display: "flex" }}
    //         className="mb-1"
    //       >
    //         <div style={{ display: "flex" }}>
    //           <div
    //             className={`seat-code ${seat?.seatClass?.toLowerCase()}`}
    //             style={{ backgroundColor: seatColors[seat?.seatClass] }}
    //           ></div>
    //           <b className="text">{seat?.title}</b>
    //         </div>

    //         <span className="text">
    //           Giá vé SHOW: {formatPrice(seat?.price)}
    //         </span>
    //         {seat?.description && (
    //           <span className="text">- {seat?.description}</span>
    //         )}

    //         {seat?.comboPrice && (
    //           <>
    //             <span className="text">
    //               Giá vé COMBO: {formatPrice(seat?.comboPrice)}
    //             </span>
    //             {seat?.comboDescription && (
    //               <span className="text">- {seat?.comboDescription}</span>
    //             )}
    //           </>
    //         )}
    //       </div>
    //     </Col>
    //   );
    // });
  };

  if (localLoading) {
    return (
      <div className="flex-center">
        <Spin spinning />
      </div>
    );
  }
  if (moment(event?.datetime).isBefore(moment())) {
    return (
      <div className="flex-center">
        <h1 className="text">Sự kiện đã kết thúc</h1>
      </div>
    );
  }

  return (
    <div
      className="flex-center event-detail-container mb-2"
      style={{ flexDirection: "column" }}
    >
      <Box
        className={`${isMobile ? "p-1" : "p-2"}`}
        style={{ width: "100%", maxWidth: 1440 }}
      >
        <Row gutter={[32, 32]}>
          <Col sm={24} md={10}>
            <img
              alt="event-img"
              style={{ width: "100%", objectFit: "contain" }}
              src={event?.posterImg}
            ></img>
          </Col>
          <Col sm={24} md={14}>
            <div className="info-container">
              <h1 className="section-title">{event?.title}</h1>
              <h3 className="address">
                <StarOutlined /> {event?.stars?.join(", ")}
              </h3>
              <h3 className="address">
                <ClockCircleOutlined /> {moment(event?.datetime).format("LL")}{" "}
                {moment(event?.datetime).format("HH:mm")} -{" "}
                {moment(event?.datetime)
                  .add(event?.duration, "minutes")
                  .format("HH:mm")}
              </h3>
              <h3 className="address">
                <PushpinOutlined /> {event?.location}
              </h3>
              <h4 className="address" style={{ marginLeft: 22 }}>
                {event?.address}
              </h4>

              {event?.timelines?.map((e: any) => (
                <h4 className="address" style={{ marginLeft: 22 }}>
                  {e}{" "}
                </h4>
              ))}

              <p className="mt-2">{event?.description}</p>
            </div>
            <h1 className="section-title flex-center">Các lựa chọn chỗ</h1>

            {renderTicketInfo()}
            <span className="text">
              <br /> LƯU Ý: <br />
              - Giá dành cho 1 người lớn <br />
              - Quý khách đã mua vui lòng không hoàn/đổi <br />
            </span>
          </Col>
        </Row>
      </Box>

      <Box className="mt-1" style={{ width: "100%", maxWidth: 1440 }}>
        <Row justify="center" style={{ width: "100%" }}>
          <Col sm={24} md={16}>
            <Stage
              bookedSeatIds={bookedSeatIds}
              event={event}
              discount={discount}
            />
          </Col>
          <Col sm={24} md={8}>
            {event?.ticketPlan === TicketPlan.SEAT ? (
              <SelectSeatControl setDiscount={setDiscount} />
            ) : (
              <SelectZoneControl setDiscount={setDiscount} />
            )}
          </Col>
        </Row>
      </Box>
    </div>
  );
};

export default EventDetail;
