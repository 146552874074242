import { createModel } from "@rematch/core";
import { RootModel } from ".";
import {
  createProgram,
  getPrograms,
  getProgram,
  updateProgram,
  deleteProgram,
} from "../apis/program";

type ProgramState = {
  programs: [];
  totalProgram: number;
  program: any;
};

export const program = createModel<RootModel>()({
  state: {
    programs: [],
    totalProgram: 0,
    program: null,
  } as ProgramState,
  reducers: {
    createProgramSuccess(state, payload: any) {
      return { ...state, program: payload };
    },
    getProgramsSuccess(state, payload: any) {
      return {
        ...state,
        programs: payload.rows,
        totalProgram: payload.count,
      };
    },
    getProgramSuccess(state, payload: any) {
      return { ...state, program: payload };
    },
    updateState(state, payload: any) {
      state = { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    async createProgram(payload: any, state) {
      const res = await createProgram(payload);
      if (res) {
        dispatch.program.getPrograms({});
        dispatch.program.createProgramSuccess(res.data?.result);
      }
    },
    async updateProgram(payload: any, state) {
      const res = await updateProgram(payload.id, payload.data);
      if (res) {
        dispatch.program.getPrograms({});
      }
    },
    async getPrograms(payload: any, state) {
      const res = await getPrograms(payload);
      if (res) {
        dispatch.program.getProgramsSuccess(res.data?.result);
      }
    },
    async getProgram(payload: any, state) {
      const res = await getProgram(payload.id, {});
      if (res) {
        dispatch.program.getProgramSuccess(res.data?.result);
      }
    },
    async deleteProgram(
      payload: {
        id: string;
        cb?: any;
      },
      state
    ) {
      const res = await deleteProgram(payload.id);
      if (res) {
        payload?.cb && payload?.cb(true);
        dispatch.program.getPrograms({});
      } else {
        payload?.cb && payload?.cb(false);
      }
    },
  }),
});
