import request from "../services/request";

export const createBooking = (payload: any) => {
  return request.post("/v1/booking", payload);
};

export const confirmBooking = (id: string) => {
  return request.patch(`/v1/booking/${id}/confirm`);
};

export const updateBooking = (id: string, payload: any) => {
  return request.patch(`/v1/booking/${id}`, payload);
};

export const getBookings = () => {
  return request.get("/v1/booking");
};

export const getBooking = (id: string) => {
  return request.get(`/v1/booking/${id}`);
};

export const deleteBooking = (id: string) => {
  return request.delete(`/v1/booking/${id}`);
};

export const applyDiscount = (id: string, code: string) => {
  return request.post(`/v1/booking/${id}/apply-discount`, { code });
};
