import { Col, Form, message, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Input } from "../../components";
import { Box } from "../../components/Box";
import "./ConfirmPayment.scss";

import {
  ArrowLeftOutlined,
  BookOutlined,
  ClockCircleOutlined,
  MoneyCollectOutlined,
  PushpinOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { formatPrice } from "../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../rematch/store";
import moment from "moment";
import * as Socket from "../../sockets/core/booking";
import Countdown from "../../components/Countdown";
import useLoading from "../../hooks/useLoading";
import { TicketPlan } from "../../models/type/event";
import { Zone } from "../../models/type/seat";

const ConfirmPayment = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch<Dispatch>();

  const bookingDetail = useSelector(
    (state: RootState) => state.booking.bookingDetail
  );

  const booking = useSelector((state: RootState) => state.booking.booking);
  const event = useSelector((state: RootState) => state.event.event);

  const confirmLoading = useLoading(
    (state: RootState) => state.loading.effects.booking.confirmBooking
  );
  const { id } = useParams();

  useEffect(() => {
    if (booking?.status === "APPROVED" || booking?.status === "CONFIRMED") {
      setStep(2);
    }
  }, [booking]);

  useEffect(() => {
    return () => {
      dispatch.booking.reset();
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch.booking.getBooking({ id });
      Socket.initBookingSocket(id!);
      return () => {
        Socket.disconnect(id!);
      };
    }
  }, [id]);

  const renderGroupInfo = () => {
    const blockInfo = bookingDetail?.blockInfo || [];
    return blockInfo.map((zone: Zone) => {
      if (!zone.quantity) {
        return <></>;
      }
      return (
        <>
          <div>
            {zone.title} - Số lượng:{zone.quantity}
          </div>
        </>
      );
    });
  };
  const navigate = useNavigate();
  const isMobile = useSelector((state: RootState) => state.dimension.isMobile);
  return (
    <div className="flex-center mb-2">
      <Box
        style={{
          padding: isMobile ? 12 : 24,
          maxWidth: 800,
          flexDirection: "column",
        }}
        className="flex-center"
      >
        <Link
          to={"/event/" + event?.id}
          type="text"
          style={{ color: "white", alignSelf: "flex-start" }}
        >
          <ArrowLeftOutlined /> Quay lại
        </Link>
        <Row className="mt-1">
          <Col md={12} sm={24} xs={24}>
            <div className="info-container" style={{ marginBottom: 24 }}>
              <div className="info-container">
                <img
                  alt=""
                  src={event?.posterImg}
                  style={{ width: "90%" }}
                ></img>
                <h1 className="mt-1">{event?.title}</h1>
                <h3 className="address">
                  <StarOutlined /> {event?.stars?.join(", ")}
                </h3>
                <h3 className="address">
                  <ClockCircleOutlined /> {moment(event?.datetime).format("LL")}{" "}
                  {moment(event?.datetime).format("HH:mm")} -{" "}
                  {moment(event?.datetime)
                    .add(event?.duration, "minutes")
                    .format("HH:mm")}
                </h3>
                <h3 className="address">
                  <PushpinOutlined /> {event?.location}
                </h3>
                <h4 className="address" style={{ marginLeft: 22 }}>
                  {event?.address}
                </h4>
              </div>
              <h3 className="address">
                {" "}
                <BookOutlined /> Thông tin đặt chỗ:
                {bookingDetail?.ticketPlan === TicketPlan.SEAT ? (
                  <> Ghế: {bookingDetail?.seats?.join(", ")}</>
                ) : (
                  <> {renderGroupInfo()}</>
                )}
              </h3>
            </div>
          </Col>
          <Col
            md={12}
            sm={24}
            xs={24}
            className="flex-center"
            style={{ flexDirection: "column" }}
          >
            {step !== 2 && (
              <div className="my-1">
                <div className="text">Thời gian giữ chỗ còn lại</div>
                <div>
                  <Countdown
                    date={booking?.releaseTime}
                    onTimeUp={() => {
                      message.info(
                        "Bạn đã hết thời gian đặt chỗ, vui lòng thử lại!",
                        3
                      );
                      navigate(`/event/${event?.id}`);
                    }}
                  />
                </div>
              </div>
            )}

            {step === 1 && (
              <>
                <div className="my-2">
                  <h3 className="address">
                    <MoneyCollectOutlined /> Số tiền thanh toán:{" "}
                    <h1 className="text" style={{ fontSize: 36 }}>
                      {formatPrice(bookingDetail?.totalPrice)}
                    </h1>
                  </h3>
                </div>

                <span className="text">
                  HƯỚNG DẪN THANH TOÁN Thông tin chuyển khoản:
                  <br />- Ngân hàng: MB Bank <br />- Số TK: 9990160690999 <br />
                  - Chủ tài khoản: Do Tuan Hai <br />
                  NỘI DUNG CHUYỂN KHOẢN:{" "}
                  <b>
                    {moment(event?.datetime).format("DDMM")}_
                    {bookingDetail.phone}{" "}
                  </b>
                  <br />
                  <br /> Quý khách vui lòng chuyển khoản số tiền cần thanh toán
                  kèm theo NỘI DUNG CHUYỂN KHOẢN như hướng dẫn. Sau khi xác nhận
                  chuyển khoản, TheBROS sẽ liên hệ và gửi lại bạn mã QR code
                  thông qua email, vui lòng bảo mật code để đổi vé tại sảnh sự
                  kiện.
                </span>
                <div className="flex-center my-2">
                  <Button
                    loading={confirmLoading.loading}
                    onClick={() => {
                      dispatch.booking.confirmBooking({
                        id,
                        cb: () => {
                          setStep(2);
                        },
                      });
                    }}
                  >
                    Tôi đã chuyển khoản
                  </Button>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <span className="text">
                  Hệ thống xác nhận đã nhận được yêu cầu đặt chỗ của quý khách,
                  sau khi xác nhận chuyển khoản, TheBROS sẽ liên hệ và gửi lại
                  bạn mã QR code thông qua email, vui lòng bảo mật mã code để
                  đổi vé tại sảnh sự kiện.
                </span>
              </>
            )}
          </Col>
        </Row>
      </Box>
    </div>
  );
};

export default ConfirmPayment;
