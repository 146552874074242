import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Radio,
  Row,
  Col,
  Form,
  Input,
  message,
  InputNumber,
  Button,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../components/Button/Button";
import Countdown from "../../../components/Countdown";
import useLoading from "../../../hooks/useLoading";
import { Seat } from "../../../models/type/seat";
import { Dispatch, RootState } from "../../../rematch/store";
import { formatPrice } from "../../../utils";
import { checkLeftEmptySeat, groupByBlockAndRow } from "../Stage/helper";
import { TicketType } from "../Stage/Stage";

const SelectSeatControl = (props: any) => {
  const { setDiscount } = props;

  const dispatch = useDispatch<Dispatch>();
  const [form] = Form.useForm();
  const [timeUp, setTimeUp] = useState(true);
  const bookingDetail = useSelector(
    (state: RootState) => state.booking.bookingDetail
  );

  const user = useSelector((state: RootState) => state.auth.user);
  const event = useSelector((state: RootState) => state.event.event);
  const createLoadingState = useLoading(
    (state: RootState) => state.loading.effects.booking.createBooking
  );
  const [isLeftEmptySeat, setIsLeftEmptySeat] = useState(false);

  const selectedSeats = useSelector(
    (state: RootState) => state.booking.selectedSeats
  );

  const unavailableSeatIds = useSelector(
    (state: RootState) => state.booking.unavailableSeatIds
  );

  const bookedSeatIds = useSelector(
    (state: RootState) => state.booking.bookedSeatIds
  );

  useEffect(() => {
    const seat = groupByBlockAndRow(selectedSeats);
    checkLeftEmptySeat(
      seat,
      [...bookedSeatIds, ...unavailableSeatIds],
      event?.stageId || "10000",
      (res: boolean) => {
        setIsLeftEmptySeat(!res);
      }
    );

    return () => {
      setIsLeftEmptySeat(false);
    };
  }, [bookedSeatIds, event?.stageId, selectedSeats, unavailableSeatIds]);

  const releaseTime = useSelector(
    (state: RootState) => state.booking.releaseTime
  );

  const onNextClick = async () => {
    if (bookingDetail.totalPrice === 0) {
      message.error("Vui lòng chọn chỗ");
      return;
    }
    dispatch.booking.createBooking({
      ...bookingDetail,
      ...(await form.validateFields()),
      cb: (res: any) => {
        dispatch.booking.updateBookingState({
          selectedSeats: [],
        });
        navigate(`/checkout/${res.id}`);
      },
    });
  };

  const applyCouponClick = async () => {
    const code = await form.getFieldValue("discountCode");
    if (!event) {
      return;
    }
    dispatch.booking.validateCoupon({
      eventId: event.id,
      code,
      cb: (res: any) => {
        setDiscount(res);
        if (res) {
          message.success("Áp dụng mã giảm giá thành công");
        } else {
          message.info("Mã giảm giá không tồn tại hoặc đã hết hạn");
        }
      },
    });
  };

  useEffect(() => {
    if (releaseTime) {
      if (moment(releaseTime).isSameOrAfter(moment())) {
        setTimeUp(false);
      } else {
        setTimeUp(true);
      }
    } else {
      setTimeUp(true);
    }
    return () => {
      setTimeUp(false);
    };
  }, [releaseTime]);

  const selectedSeatMap =
    useSelector((state: RootState) => state.booking.selectedSeatMap) ||
    new Map();

  const priceMap = useSelector((state: RootState) => state.booking.priceMap);

  const updatePrice = (
    seat: Seat,
    selectedPrice: number,
    selectedPriceType: string,
    quantity: number = 1,
    isRemove?: boolean
  ) => {
    dispatch.booking.updatePrice({
      seat,
      selectedPrice,
      selectedPriceType,
      isRemove,
      quantity,
    });
  };

  const renderTicketBox = () => {
    const info = selectedSeats.map((seat: Seat, index: number) => {
      const priceInfo = priceMap[seat.seatClass];

      const hasCombo = !!priceInfo?.comboPrice;
      return (
        <div className="ticket-box mb-1" key={index}>
          <b>Ghế: {seat.seatId}</b>
          {hasCombo && (
            <Radio.Group className="ml-1" defaultValue={0}>
              <Radio
                value={0}
                onClick={() =>
                  updatePrice(
                    seat,
                    priceInfo.comboPrice,
                    TicketType.COMBO,
                    selectedSeatMap[seat.seatId].quantity || 1
                  )
                }
              >
                Combo
              </Radio>
              <Radio
                value={1}
                onClick={() =>
                  updatePrice(
                    seat,
                    priceInfo.price,
                    TicketType.STANDARD,
                    selectedSeatMap[seat.seatId].quantity || 1
                  )
                }
              >
                Show only
              </Radio>
            </Radio.Group>
          )}
          {seat.seatClass === "SUN" && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="ml-1">Số lượng</div>
              <InputNumber
                className="common-input"
                onClick={() => {
                  if (!user) {
                    dispatch.auth.setAuthModalVisible(true);
                    return;
                  }
                }}
                min={2}
                max={3}
                defaultValue={2}
                value={selectedSeatMap[seat.seatId]?.quantity || 2}
                style={{ marginLeft: 12 }}
                controls={false}
                onChange={(value) => {
                  updatePrice(
                    seat,
                    priceInfo.comboPrice,
                    selectedSeatMap[seat.seatId].selectedPriceType,
                    value
                  );
                }}
              />
              <span style={{ fontSize: 12, marginLeft: 16 }}>
                {" "}
                <InfoCircleOutlined style={{ fontSize: 12 }} /> Số lượng tối
                thiểu: 2 - Số lượng tối đa: 3
              </span>
            </div>
          )}

          <div className="ticket-box-content ml-1">
            Giá:{" "}
            <b>
              {" "}
              {formatPrice(selectedSeatMap[seat.seatId]?.selectedPrice)}x{" "}
              {selectedSeatMap[seat.seatId]?.quantity || 1}
            </b>
          </div>
        </div>
      );
    });
    return (
      <div>
        {!timeUp && (
          <div>
            <div className="text">Thời gian giữ chỗ còn lại</div>
            <Countdown date={releaseTime} onTimeUp={() => setTimeUp(true)} />
          </div>
        )}

        {info}
        <Form
          form={form}
          className="common-form"
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 540 }}
          autoComplete="off"
        >
          <Form.Item
            initialValue={user?.name}
            label={"Họ và tên"}
            name="name"
            rules={[{ required: true, message: "Vui lòng nhập họ và tên" }]}
          >
            <Input className="common-input"></Input>
          </Form.Item>
          <Form.Item
            initialValue={user?.phone}
            label={"Số điện thoại"}
            name="phone"
            rules={[{ required: true, message: "Vui lòng nhập số điện thoại" }]}
          >
            <Input className="common-input"></Input>
          </Form.Item>
          <Form.Item
            initialValue={user?.email}
            label={"Email"}
            name="email"
            rules={[
              { required: true, message: "Vui lòng nhập số điện thoại" },
              {
                type: "email",
                message: "Vui lòng nhập đúng định dạng email",
              },
            ]}
          >
            <Input className="common-input"></Input>
          </Form.Item>
          <Row className="w-100">
            <Col span={18}>
              <Form.Item label={"Mã giảm giá"} name="discountCode">
                <Input className="common-input"></Input>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  marginTop: 41,
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  fontSize: 12,
                  height: 30,
                }}
                onClick={applyCouponClick}
              >
                Áp dụng
              </Button>
            </Col>
          </Row>
        </Form>
        <Row justify="space-between" className="w-100">
          <Col>
            <h3 className="price-title" style={{ color: "white" }}>
              Thành tiền
            </h3>
          </Col>

          <Col className="flex-hcenter">
            {bookingDetail.subPrice &&
              bookingDetail.subPrice !== bookingDetail.totalPrice && (
                <b
                  style={{
                    color: "white",
                    fontSize: 24,
                    textDecoration: "line-through",
                  }}
                >
                  {formatPrice(bookingDetail.subPrice || 0)}
                </b>
              )}
            <b style={{ color: "white", fontSize: 24 }}>
              {formatPrice(bookingDetail.totalPrice || 0)}
            </b>
          </Col>
        </Row>
      </div>
    );
  };
  const navigate = useNavigate();
  // if (!selectedSeats?.length) {
  //   return <></>;
  // }
  return (
    <Row
      style={{ width: "100%" }}
      justify="center"
      className="mb-3"
      gutter={[16, 16]}
    >
      <Col>
        <Row style={{ flexDirection: "column" }}>
          <h1 className="section-title flex-center mt-2">Thông tin đặt chỗ</h1>
          <>
            {isLeftEmptySeat && (
              <b className="text" style={{ color: "red" }}>
                Vui lòng không để ghế lẻ
              </b>
            )}
            {renderTicketBox()}
            <CommonButton
              disabled={!selectedSeats.length || isLeftEmptySeat}
              loading={createLoadingState.loading}
              className="my-2"
              onClick={onNextClick}
            >
              Tiếp tục
            </CommonButton>
          </>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectSeatControl;
