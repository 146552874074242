import React from "react";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../rematch/store";

const Map = () => {
  const dimension = useSelector((state: RootState) => state.dimension);
  return (
    <div
      className="flex-center"
      style={{
        height: Math.min(dimension?.screenWidth + 70, 720),
        width: "100%",
        position: "relative",
      }}
    >
      <Row
        style={{
          zIndex: 99,
          position: "absolute",
          top: 64,
          // width: "100%",
          maxWidth: 720,
          backgroundColor: "#ffe818",
          border: "solid 5px #ffe818",
          margin: 12,
        }}
        justify="space-between"
        gutter={[32, 32]}
      >
        <Col sm={12} md={12}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: dimension?.isMobile ? 16 : 20,
            }}
          >
            CÔNG VIÊN YÊN SỞ
          </div>
          <div
            style={{
              fontFamily: "Inconsolata",
              fontSize: dimension?.isMobile ? 14 : 20,
              lineHeight: "12px",
              fontWeight: "bold",
            }}
          >
            Gamuda City, HOANG MAI,
          </div>
          <div
            style={{
              fontFamily: "Inconsolata",
              fontSize: dimension?.isMobile ? 14 : 20,
              lineHeight: dimension?.isMobile ? "12px" : "24px",
              fontWeight: "bold",
            }}
          >
            HANOI, VIETNAM
          </div>
        </Col>
        <Col sm={12} md={12}>
          <div
            style={{
              fontSize: dimension?.isMobile ? 22 : 48,
              fontWeight: "bold",
              lineHeight: dimension?.isMobile ? "22px" : "42px",
              textAlign: "right",
            }}
          >
            06.08.22
          </div>
        </Col>
        <div className="flex-center w-100">
          <div
            style={{
              zIndex: 99,
              maxHeight: 480,
              height:
                dimension?.screenWidth > 720
                  ? 470
                  : dimension?.isMobile
                  ? ((dimension.screenWidth - 24) * 9) / 16
                  : ((dimension.screenWidth / 2) * 9) / 16,
              width: "100%",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7451.291112684999!2d105.85272827613525!3d20.966744512609157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac34ca0c7c45%3A0xf9c593f4397d0470!2sYen%20So%20Park!5e0!3m2!1sen!2shk!4v1656147198641!5m2!1sen!2shk"
              style={{
                border: "none",
              }}
              height="100%"
              width="100%"
              loading="lazy"
              title="map"
            />
          </div>
        </div>
      </Row>

      <img
        alt="bg"
        src="/hfest/bg_1.png"
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1,
          objectFit: "cover",
          height: "100%",
        }}
      />
    </div>
  );
};

export default Map;
