import { IGroupType } from "./group-type";
import { ISeatType } from "./seat-type";
import { IStage } from "./stage";

export enum TicketPlan {
  SEAT = "SEAT",
  GROUP = "GROUP",
}

export interface IEvent {
  id: string;
  title: string;
  posterImg: string;
  stars: string[];
  datetime: string;
  duration: number;
  stageId: string;
  location?: string;
  address?: string;
  timelines?: string[];
  description?: string;
  createdByUserId?: string;
  modifiedByUserId?: string;
  visible?: boolean;
  ticketPlan: TicketPlan;
  programId: string;
  seatPlanImg?: string;

  readonly stage?: IStage;
  readonly seatTypes?: ISeatType[];
  readonly groupTypes?: IGroupType[];
  [key: string]: any;
}
