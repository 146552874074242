import { Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "../../../components";

import "./ConfirmPayment.scss";

import {
  ArrowLeftOutlined,
  BookOutlined,
  FacebookFilled,
  InstagramOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { formatPrice } from "../../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../rematch/store";
import * as Socket from "../../../sockets/core/booking";
import Countdown from "../../../components/Countdown";
import useLoading from "../../../hooks/useLoading";
import { Zone } from "../../../models/type/seat";
import Header from "../Header";

function removeAccents(str: string) {
  return str
    ?.normalize("NFD")
    ?.replace(/[\u0300-\u036f]/g, "")
    ?.replace(/đ/g, "d")
    ?.replace(/Đ/g, "D");
}

const ConfirmPayment = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch<Dispatch>();

  const bookingDetail = useSelector(
    (state: RootState) => state.booking.bookingDetail
  );

  const booking = useSelector((state: RootState) => state.booking.booking);
  const event = useSelector((state: RootState) => state.event.event);

  const confirmLoading = useLoading(
    (state: RootState) => state.loading.effects.booking.confirmBooking
  );
  const { id } = useParams();

  useEffect(() => {
    if (booking?.status === "APPROVED" || booking?.status === "CONFIRMED") {
      setStep(2);
    }
  }, [booking]);

  useEffect(() => {
    return () => {
      dispatch.booking.reset();
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch.booking.getBooking({ id });
      Socket.initBookingSocket(id!);
      return () => {
        Socket.disconnect(id!);
      };
    }
  }, [id]);

  const renderGroupInfo = () => {
    const blockInfo = bookingDetail?.blockInfo || [];
    return blockInfo.map((zone: Zone) => {
      if (!zone.quantity) {
        return <></>;
      }
      return (
        <>
          <div>
            {zone?.title} - Số lượng:{zone.quantity}
          </div>
        </>
      );
    });
  };
  const navigate = useNavigate();
  const isMobile = useSelector((state: RootState) => state.dimension.isMobile);
  const dimension = useSelector((state: RootState) => state.dimension);

  return (
    <div
      className="hayfest-checkout flex-hcenter"
      style={{ maxWidth: dimension.screenWidth }}
    >
      <Header />
      <Row style={{ width: "100%", maxWidth: 960 }} className="container">
        <Link
          to={"/hayfest/booking"}
          type="text"
          style={{ alignSelf: "flex-start" }}
        >
          <ArrowLeftOutlined /> Quay lại
        </Link>

        <Row className="mt-1" gutter={[32, 32]} justify="center">
          {step === 1 && (
            <>
              <Col md={11} sm={24} xs={24}>
                <div className="info-container" style={{ marginBottom: 24 }}>
                  <div className="info-container">
                    <img
                      alt=""
                      src={event?.posterImg}
                      style={{ width: "100%" }}
                    ></img>

                    <div className="text mt-2">
                      <StarOutlined /> Quý khách vui lòng chuyển khoản số tiền
                      cần thanh toán kèm theo NỘI DUNG CHUYỂN KHOẢN như hướng
                      dẫn. Sau khi xác nhận chuyển khoản, TheBROS sẽ liên hệ và
                      gửi lại bạn mã QR code thông qua email, vui lòng bảo mật
                      code để đổi vé tại sảnh sự kiện.
                    </div>
                  </div>
                  <div className="info-title mt-2">
                    <BookOutlined /> Thông tin đặt chỗ:
                    {renderGroupInfo()}
                  </div>
                </div>
              </Col>
              {!isMobile && <div className="dvd"></div>}
              <Col md={11} sm={24} xs={24} style={{ flexDirection: "column" }}>
                <div className="my-1">
                  <div className="text info-title">
                    Thời gian giữ chỗ còn lại
                  </div>
                  <div>
                    <Countdown
                      date={booking?.releaseTime}
                      onTimeUp={() => {
                        message.info(
                          "Bạn đã hết thời gian đặt chỗ, vui lòng thử lại!",
                          3
                        );
                        navigate(`/event/${event?.id}`);
                      }}
                    />
                  </div>
                </div>
                <div className="my-2">
                  <div className=" info-title">Số tiền thanh toán: </div>
                  <h1 className="count-down-text">
                    {formatPrice(bookingDetail?.totalPrice)}
                  </h1>
                </div>

                <span className="text">
                  <div className=" info-title">HƯỚNG DẪN THANH TOÁN </div>
                  Thông tin chuyển khoản:
                  <br />- Ngân hàng: MB Bank <br />- Số TK:
                  <b style={{ textDecoration: "underline" }}>
                    {" "}
                    0690111795009
                  </b>{" "}
                  <br />
                  - Chủ tài khoản: Do Tuan Hai <br />
                  NỘI DUNG CHUYỂN KHOẢN: <br />
                  <b>
                    Hayfest {removeAccents(bookingDetail?.name)}{" "}
                    {bookingDetail.phone}
                  </b>
                  <br />
                  <br /> Quý khách vui lòng chuyển khoản số tiền cần thanh toán
                  kèm theo NỘI DUNG CHUYỂN KHOẢN như hướng dẫn. Sau khi xác nhận
                  chuyển khoản, TheBROS sẽ liên hệ và gửi lại bạn mã QR code
                  thông qua email, vui lòng bảo mật code để đổi vé tại sảnh sự
                  kiện.
                </span>
                <div className="flex-center my-2">
                  <Button
                    shape="default"
                    className="confirm-btn"
                    loading={confirmLoading.loading}
                    onClick={() => {
                      dispatch.booking.confirmBooking({
                        id,
                        cb: () => {
                          setStep(2);
                        },
                      });
                    }}
                  >
                    Tôi đã chuyển khoản
                  </Button>
                </div>
              </Col>
            </>
          )}
          {step === 2 && (
            <Col span={24} style={{ minHeight: 320, padding: 32 }}>
              <div className="flex-center">
                <img
                  style={{ height: "100%", width: "100%", maxWidth: 320 }}
                  src="/hfest/confirm.png"
                  alt="confirm"
                />
              </div>
              <br />
              <span className="text">
                Hệ thống xác nhận đã nhận được yêu cầu đặt chỗ của quý khách,
                sau khi xác nhận chuyển khoản, TheBROS sẽ liên hệ và gửi lại bạn
                mã QR code thông qua email, vui lòng bảo mật mã code để đổi vé
                tại sảnh sự kiện.
              </span>
              <br />
              <br />

              <span>
                Bạn đừng quên follow fanpage HAY Glamping Music Festival để cập
                nhật các thông tin về sự kiện
              </span>
              <div className="flex-center mt-1">
                <a
                  className="mr-2 flex-center"
                  target="blank"
                  href="https://www.facebook.com/HAYfest"
                >
                  {" "}
                  <FacebookFilled style={{ fontSize: 24 }} /> @hayfest
                </a>
                <a
                  className="flex-center"
                  target="blank"
                  href="https://www.instagram.com/hay.fest"
                >
                  {" "}
                  <InstagramOutlined style={{ fontSize: 24 }} /> @hayfest
                </a>
              </div>
              <div className="flex-center my-2">
                <Button
                  shape="default"
                  className="confirm-btn"
                  onClick={() => {
                    navigate("/hayfest");
                  }}
                >
                  QUAY VỀ TRANG CHỦ
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </Row>
      <img
        alt="hi"
        src="/hfest/bg_3.jpg"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
        }}
      />
    </div>
  );
};

export default ConfirmPayment;
