import React from "react";

import { Button, Col, Row } from "antd";
import { formatPrice } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../rematch/store";
import { Link } from "react-router-dom";
import _ from "lodash";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

const Price = () => {
  const event = useSelector((state: RootState) => state.event.event);
  const dimension = useSelector((state: RootState) => state.dimension);

  const renderPrice = () => {
    return _.sortBy(event?.groupTypes, "order")?.map((e) => {
      const remainSeat =
        e.totalSeat - e.bookedSeat >= 0 ? e.totalSeat - e.bookedSeat : 0;
      return (
        <Col
          sm={12}
          xs={24}
          md={8}
          lg={6}
          className="flex-center"
          style={{ alignItems: "unset" }}
        >
          <div className="price-box">
            {e?.isNew && (
              <img
                style={{ width: 36, position: "absolute", right: 26, top: 4 }}
                src="/hfest/new.png"
                alt="new"
              />
            )}
            <div style={{ opacity: remainSeat ? 1 : 0.5 }}>
              <div className="title">{e.title}</div>
              <div className="seat-class">
                {e.seatClass.startsWith("VIP") || e.seatClass.startsWith("NFT")
                  ? "HẠNG VIP"
                  : "HẠNG GENERAL ADMISSION"}
              </div>
              <div className="price">
                {!e.redirectUrl ? formatPrice(e?.price) : `50 BUSD/USDT`}
              </div>
              <div className="description">{e.description}</div>
            </div>
            <div style={{ position: "relative", marginTop: 6 }}>
              {remainSeat > 0 ? (
                <Link to="/hayfest/booking">
                  <Button className="btn">Đặt mua</Button>
                </Link>
              ) : (
                <img
                  style={{
                    height: 45,
                    position: "absolute",
                    right: -12,
                    bottom: 0,
                  }}
                  src="/hfest/sold_out.png"
                  alt="sold-out"
                />
              )}
            </div>
          </div>
        </Col>
      );
    });
  };

  const getHeight = () => {
    if (dimension.screenWidth > 1280) {
      return 1000;
    }
    if (dimension.screenWidth > 960) {
      return 1000;
    }
    if (dimension.screenWidth > 800) {
      return 1400;
    }
    if (dimension.screenWidth > 640) {
      return 1700;
    }
    if (dimension.screenWidth > 520) {
      return 3500;
    }
    if (dimension.screenWidth > 480) {
      return 3500;
    }
    if (dimension.screenWidth < 480) {
      return 3500;
    }
  };
  return (
    <Row
      className="flex-center"
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        // maxHeight: getHeight(),
      }}
    >
      <Row
        style={{
          zIndex: 99,
          marginTop: 64,
          width: dimension.screenWidth,
          // overflow: "hidden",
        }}
        justify="center"
      >
        <Col span={24} className="flex-hcenter">
          <div
            style={{
              color: "#ffe818",
              fontSize: 48,
              fontWeight: "bold",
              lineHeight: "42px",
              textAlign: "center",
            }}
          >
            BẢNG GIÁ VÉ
          </div>
          <Row
            style={{
              width: "100%",
              maxWidth: 1280,
            }}
            className="mt-2"
            justify="center"
            gutter={[12, 12]}
          >
            {renderPrice()}
          </Row>
        </Col>
        <Col span={24} className="py-3 px-1" style={{ maxWidth: 1280 }}>
          {/* <Row justify="space-between">
            <Col>
              <div
                className="flex-center"
                style={{
                  zIndex: 1,
                  bottom: 36,
                  left: 36,
                }}
              >
                <img
                  alt="bg"
                  src="/hfest/thebros_logo.png"
                  style={{
                    zIndex: 1,
                    objectFit: "contain",
                    height: 16,
                  }}
                />
                <div style={{ color: "#ffe818" }}>
                  {" "}
                  ©THEBROS ENTERTAINMENT 2022
                </div>
              </div>
            </Col>
            <Col>
              <span className="follow">
                <Row style={{ color: "#ffe818" }}>
                  FOLLOW US ON{" "}
                  <a
                    className="flex-center ml-1"
                    target="blank"
                    href="https://www.facebook.com/HAYfest"
                  >
                    <FacebookOutlined
                      style={{ fontSize: 16, color: "#ffe818" }}
                    />
                  </a>
                  <a
                    className="flex-center ml-1"
                    target="blank"
                    href="https://www.instagram.com/hay.fest"
                  >
                    <InstagramOutlined
                      style={{ fontSize: 16, color: "#ffe818" }}
                    />
                  </a>
                </Row>
              </span>
            </Col>
          </Row> */}
        </Col>
      </Row>

      <img
        alt="bg"
        src="/hfest/bg_3.jpg"
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1,
          objectFit: "cover",
          height: "100%",
        }}
      />
    </Row>
  );
};

export default Price;
