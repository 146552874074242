import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Header } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../rematch/store";
import Banner from "./Banner";
import "./HayFest.scss";
import Intro from "./Intro";
import LineUp from "./LineUp";
import Map from "./Map";
import Price from "./Price";
import Marquee from "react-fast-marquee";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const HayFest = () => {
  useEffect(() => {
    // redirect to hayfest.vn
    window.location.href = "https://hayfest.vn";
    return () => {};
  }, []);

  const dimension = useSelector((state: RootState) => state.dimension);
  const dispatch = useDispatch<Dispatch>();

  const menuConfis = [
    {
      title: "Giới thiệu",
      icon: "/hfest/m_icon_1.png",
      id: "intro",
    },
    {
      title: "Nghệ sĩ",
      icon: "/hfest/m_icon_2.png",
      id: "lineup",
    },
    {
      title: "Địa điểm",
      icon: "/hfest/m_icon_3.png",
      id: "location",
    },
    {
      title: "giá vé",
      icon: "/hfest/m_icon_4.png",
      id: "price",
    },
  ];
  useEffect(() => {
    dispatch.event.getEventByProgramId({
      programId: "10002",
      cb: () => {},
    });
  }, []);
  const renderMenu = () => {
    return menuConfis.map((e) => (
      <Col span={6}>
        <div
          className="menu-item"
          onClick={() => {
            document
              .getElementById(e.id)
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          {/* <a href="#price"> */}
          <img className="menu-icon" src={e.icon} alt="icon" />
          <span className="menu-title">{e.title}</span>
          {/* </a> */}
        </div>
      </Col>
    ));
  };
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>HAY Glamping Music Festival</title>

        <meta
          name="description"
          content="Lễ hội âm nhạc quốc tế mùa hè HAY Glamping Music Festival 2022"
        />
        <meta property="og:title" content="HAY Glamping Music Festival" />
        <meta
          property="og:description"
          content="Lễ hội âm nhạc quốc tế mùa hè HAY Glamping Music Festival 2022"
        />
        <meta property="og:url" content="https://thebros.vn/hayfest" />
        <meta
          property="og:image"
          content="https://thebros.vn/hfest/banner.jpg"
        />
      </Helmet>
      <img
        style={{
          width: dimension?.isMobile ? 72 : 128,
          height: dimension?.isMobile ? 72 : 128,
          position: "fixed",
          bottom: dimension?.isMobile ? 24 : 96,
          right: dimension?.isMobile ? 24 : 48,
          cursor: "pointer",
          zIndex: 9999,
        }}
        onClick={() => navigate("/hayfest/booking")}
        src="/hfest/book_btn.png"
        alt="book_btn"
      />
      <Row>
        <Header style={{ backgroundColor: "#FFE818", width: "100%" }}>
          <Row className="header-row" justify="center">
            <Col
              flex={1}
              className={`${dimension?.isDesktop ? "" : "flex-center"}`}
            >
              <img
                style={{
                  height: "100%",
                  width: "50%",
                  objectFit: "contain",
                  padding: 12,
                }}
                alt="logo"
                src="/hfest/logo.png"
              />
              <img
                style={{
                  height: "100%",
                  width: "50%",
                  objectFit: "contain",
                  padding: 12,
                }}
                alt="logo"
                src="/hfest/sub.png"
              />
            </Col>
            {dimension.isDesktop && (
              <>
                <Col flex={1}>
                  <Row
                    gutter={[6, 6]}
                    style={{ width: "100%", minWidth: 560 }}
                    justify="center"
                  >
                    {renderMenu()}
                  </Row>
                </Col>
                <Col flex={1}>
                  <Row gutter={[32, 32]} justify="end">
                    <Col>
                      <span className="place">HA NOI</span>
                    </Col>
                    <Col>
                      <span className="date">06.08.22</span>
                    </Col>
                    <Col>
                      <span className="follow">
                        <Row>
                          FOLLOW US ON{" "}
                          <a
                            className="flex-center ml-1"
                            target="blank"
                            href="https://www.facebook.com/HAYfest"
                          >
                            {" "}
                            <FacebookOutlined
                              style={{ fontSize: 16, color: "black" }}
                            />
                          </a>
                          <a
                            className="flex-center ml-1"
                            target="blank"
                            href="https://www.instagram.com/hay.fest"
                          >
                            {" "}
                            <InstagramOutlined
                              style={{ fontSize: 16, color: "black" }}
                            />
                          </a>
                        </Row>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Header>
        <Banner />
        <Marquee gradient={false}>
          <div style={{ height: 54 }}>
            <img
              style={{
                height: "100%",
                objectFit: "contain",
                backgroundColor: "#f1f3ec",
              }}
              src="/hfest/divider_1.png"
              alt="dvd"
            />
            <img
              style={{
                height: "100%",
                objectFit: "contain",
                backgroundColor: "#f1f3ec",
              }}
              src="/hfest/divider_1.png"
              alt="dvd"
            />
          </div>
        </Marquee>
        <div id="intro">
          <Intro />
        </div>
        <Marquee gradient={false}>
          <Row style={{ height: 54 }}>
            <img
              style={{
                height: "100%",
                objectFit: "contain",
              }}
              src="/hfest/divider_2.png"
              alt="dvd"
            />
            <img
              style={{
                height: "100%",
                objectFit: "contain",
              }}
              src="/hfest/divider_2.png"
              alt="dvd"
            />
          </Row>
        </Marquee>
        <Marquee gradient={false} direction="right">
          <div
            style={{
              backgroundColor: "#189ED6",
              padding: 16,
              display: "flex",
              height: 54,
            }}
          >
            {Array.from(Array(17).keys()).map((e, i) => (
              <img
                style={{
                  objectFit: "contain",
                  height: "100%",
                  marginRight: i === 16 ? 0 : 24,
                }}
                src="/hfest/logo_1.png"
                alt="dvd"
              />
            ))}
          </div>
        </Marquee>
        <div id="lineup">
          <LineUp />
        </div>
        <Marquee gradient={false}>
          <div style={{ height: 54 }}>
            <img
              style={{
                height: "100%",
                objectFit: "contain",
              }}
              src="/hfest/divider_4.png"
              alt="dvd"
            />
            <img
              style={{
                height: "100%",
                objectFit: "contain",
              }}
              src="/hfest/divider_4.png"
              alt="dvd"
            />
          </div>
        </Marquee>
        <div id="location"></div>
        <Map />

        <Marquee direction="right" gradient={false}>
          <div style={{ height: 54 }}>
            <img
              style={{
                height: "100%",
                objectFit: "contain",
              }}
              src="/hfest/divider_5.png"
              alt="dvd"
            />
            <img
              style={{
                height: "100%",
                objectFit: "contain",
              }}
              src="/hfest/divider_5.png"
              alt="dvd"
            />
          </div>
        </Marquee>
        <Layout />
        <Row id="price">
          <Col span={24}>
            <Price />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default HayFest;
