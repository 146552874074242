import React from "react";
import { Form, Input, Button, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../rematch/store";
import { Navigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";

const ForgotPassword = () => {
  const dispatch = useDispatch<Dispatch>();

  // get params in url
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const email = params.get("email");

  const onFinish = (values: any) => {
    dispatch.auth.forgotPassword(values);
  };

  const user = useSelector((state: RootState) => state.auth.user);
  const { loading, finished, success, error } = useLoading(
    (state: RootState) => state.loading.effects.auth.forgotPassword
  );

  if (user) {
    dispatch.auth.setAuthModalVisible(false);
    dispatch.auth.changeAuthPage("login");
  }

  return (
    <Form style={{ marginTop: 32 }} onFinish={onFinish}>
      <div className="flex-center">
        <h1>Quên mật khẩu</h1>
      </div>

      {finished && success ? (
        <Row>
          <span>
            Một thư đặt lại mật khẩu vừa được gửi tới địa chỉ email của bạn. Vui
            lòng kiểm tra email
          </span>
        </Row>
      ) : (
        <>
          <Form.Item
            initialValue={email}
            name="email"
            rules={[
              { required: true, message: "Vui lòng không để trống email" },
              { type: "email", message: "Định dạng email không đúng" },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          {finished && error && (
            <Row>
              <span style={{ color: "red", fontSize: 12, width: "100%" }}>
                Không tìm thấy người dùng
              </span>
            </Row>
          )}
          <Form.Item>
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
              className="login-form-button mt-2"
              style={{ width: "100%" }}
            >
              Lấy lại mật khẩu
            </Button>
          </Form.Item>
          Hoặc{" "}
          <Button
            className="px-0"
            type="link"
            onClick={() => dispatch.auth.changeAuthPage("login")}
          >
            đăng nhập
          </Button>
        </>
      )}
    </Form>
  );
};

export default ForgotPassword;
