import { Layout } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthModule from "../pages/Auth";
import { Dispatch, RootState } from "../rematch/store";

const HayFestLayout: React.FC = (props) => {
  const dispatch = useDispatch<Dispatch>();
  const dimension = useSelector((state: RootState) => state.dimension);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
      dispatch.dimension.updateDimension({
        screenWidth,
        screenHeight,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch.dimension]);
  const isD = dimension.screenWidth >= 1024;
  return (
    <>
      <AuthModule />
      <Layout
        className="hayfest"
        style={{
          minWidth: dimension.screenWidth,
          maxWidth: dimension.screenWidth,
          overflow: "auto",
        }}
      >
        {props.children}
        <div style={{ zIndex: 9999 }}>
          {isD && (
            <div
              style={{
                backgroundColor: "#ffe818",
                height: 12,
              }}
            />
          )}
          <div
            style={{
              backgroundColor: "black",
              padding: !isD ? 0 : 36,
            }}
          >
            <img
              src={isD ? `/hfest/footer.png` : "/hfest/m_footer.jpg"}
              alt="footer"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default HayFestLayout;
