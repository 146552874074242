import { initializeApp, FirebaseApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBRRVPE4-xzavyIA7_g45MzBMfY_6aTq50",
  authDomain: "thebros.firebaseapp.com",
  projectId: "thebros",
  storageBucket: "thebros.appspot.com",
  messagingSenderId: "830358132010",
  appId: "1:830358132010:web:61c42da2d19b61337a7a53",
};

let app: FirebaseApp;

export const intiFirebase = () => {
  if (!app) {
    app = initializeApp(firebaseConfig);
  }
  return app;
};
