import "./styles/index.scss";
import "./App.less";
import AppRoute from "./routes/AppRoute";
import { Provider } from "react-redux";
import { store } from "./rematch/store";
import InitState from "./containers/InitState";
import ErrorBoundary from "./containers/ErrorBoundary";
import "moment/locale/vi";
import { getPersistor } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { useEffect } from "react";
import { intiFirebase } from "./firebase";

const persistor = getPersistor();

function App() {
  useEffect(() => {
    intiFirebase();
    return () => {};
  }, []);

  return (
    <PersistGate persistor={persistor}>
      <ErrorBoundary>
        <div className="app">
          <Provider store={store}>
            <InitState />
            <AppRoute />
          </Provider>
        </div>
      </ErrorBoundary>
    </PersistGate>
  );
}

export default App;
