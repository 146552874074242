import { createModel } from "@rematch/core";
import {
  applyDiscount,
  confirmBooking,
  createBooking,
  getBooking,
} from "../apis/booking";
import { RootModel } from ".";
import { message } from "antd";
import { Seat, Zone } from "./type/seat";
import { IGroupType } from "./type/group-type";
import { TicketType } from "./type/booking-seat";

type BookingState = {
  booking: any;
  bookingDetail: any;
  unavailableSeatIds: string[];
  bookingUsers: string[];
  bookedSeatIds: string[];
  bookedSeats: any[];
  selectedSeats: [];
  selectedSeatMap: any;
  priceMap: { [key: string]: any };
  socketId: string | null;
  releaseTime: string | null;
  selectedZones: Zone[];
  selectedZoneMap: any;
  seatInfoMap: any;
};

const initState = {
  booking: null,
  bookingDetail: {},
  unavailableSeatIds: [],
  bookedSeatIds: [],
  bookedSeats: [],
  bookingUsers: [],
  selectedSeats: [],
  selectedSeatMap: {},
  priceMap: {},
  socketId: null,
  releaseTime: null,
  selectedZones: [],
  selectedZoneMap: {},
  seatInfoMap: {},
} as BookingState;

export const booking = createModel<RootModel>()({
  state: initState,
  reducers: {
    updateBookingState(state, payload: Partial<BookingState>) {
      return { ...state, ...payload };
    },
    createBookingSuccess(state, payload: any) {
      return { ...state, booking: payload };
    },
    setUpStage(state, payload: any) {
      let bookedSeatIds: any = [];
      payload?.bookings?.forEach((item: any) => {
        bookedSeatIds = [...bookedSeatIds, ...item.seats];
      });
      let priceMap: any = {};
      let seatInfoMap: any = {};
      let selectedZoneMap: any = {};

      payload?.seatTypes?.forEach((object: any) => {
        priceMap[object.seatClass] = object;
      });
      payload?.groupTypes?.forEach((object: any) => {
        priceMap[object.seatClass] = object;
        seatInfoMap[object.block] = object;
        selectedZoneMap[object.seatClass] = {
          quantity: 0,
          ...object,
          ticketType: object?.comboPrice
            ? TicketType.COMBO
            : TicketType.STANDARD,
          selectedPrice: object?.comboPrice || object?.price || 0,
        };
      });
      return {
        ...state,
        bookedSeatIds,
        priceMap,
        seatInfoMap,
        selectedZoneMap,
      };
    },
    getBookingSuccess(state, payload: any) {
      return { ...state, booking: payload };
    },
    updateBookingDetail(state, payload: any) {
      return { ...state, bookingDetail: payload };
    },
    updateUnavailableSeatIds(state, payload: any) {
      return {
        ...state,
        unavailableSeatIds: payload.unavailableSeatIds,
        bookingUsers: payload.bookingUsers,
      };
    },
    updatePrice(
      state,
      payload: {
        seat: Seat;
        selectedPrice: number;
        selectedPriceType: string;
        quantity: number;
        isRemove?: boolean;
      }
    ) {
      const tempMap = { ...state.selectedSeatMap };

      if (payload.isRemove) {
        delete tempMap[payload.seat.seatId];
      } else {
        const priceInfo = state.priceMap[payload.seat.seatClass];
        if (priceInfo) {
          tempMap[payload.seat.seatId] = {
            ...priceInfo,
            selectedPrice: payload.selectedPrice,
            selectedPriceType: payload.selectedPriceType,
            seatId: payload.seat.seatId,
            quantity: payload.quantity || 1,
            ticketType: payload?.selectedPriceType,
            block: payload.seat.block,
            row: payload.seat.row,
            position: payload.seat.position,
            price: payload.selectedPrice,
          };
        }
      }

      return { ...state, selectedSeatMap: tempMap };
    },
    updateZonePrice(
      state,
      payload: {
        zone: IGroupType;
        quantity: number;
        ticketType: TicketType;
        isRemove?: boolean;
      }
    ) {
      const tempMap = { ...state.selectedZoneMap };

      if (payload.isRemove) {
        delete tempMap[payload.zone.seatClass];
      } else {
        const priceInfo = state.priceMap[payload.zone.seatClass];
        if (priceInfo) {
          tempMap[payload.zone.seatClass] = {
            ...priceInfo,
            quantity: payload.quantity,
            block: payload.zone.seatClass,
            selectedPrice:
              payload?.ticketType === TicketType.COMBO
                ? payload.zone?.comboPrice || 0
                : payload.zone.price,
            ticketType: payload?.ticketType,
          };
        }
      }

      return { ...state, selectedZoneMap: tempMap };
    },
    reset() {
      return initState;
    },
    checkExistedSocketId(state, payload) {
      if (state.socketId !== payload.id) {
        this.reset();
      }
    },
  },
  effects: (dispatch) => ({
    async createBooking(payload: any, state: any) {
      const res = await createBooking(payload);
      if (res?.data?.result) {
        dispatch.booking.createBookingSuccess(res.data?.result);
        dispatch.booking.updateBookingDetail({
          ...state.booking.bookingDetail,
          id: res.data.result.id,
        });
        payload?.cb && payload?.cb(res.data.result);
      }
    },
    async getBooking(payload: any, state: any) {
      const res = await getBooking(payload.id);
      if (res?.data?.result) {
        dispatch.booking.updateBookingDetail({
          ...state.booking.bookingDetail,
          ...res.data.result,
        });
      }
    },

    async confirmBooking(payload: any, state: any) {
      try {
        const res = await confirmBooking(payload.id);
        if (res) {
          payload?.cb && payload?.cb(true);
        }
      } catch (error) {
        message.error("Lỗi hệ thống");
      }
    },
    async validateCoupon(
      payload: {
        eventId: string;
        code: string;
        cb?: any;
      },
      state: any
    ) {
      const cb = payload?.cb;
      try {
        delete payload.cb;
        const res = await applyDiscount(payload.eventId, payload.code);
        if (res?.data?.result) {
          payload?.cb && payload?.cb(true);
          cb(res?.data?.result);
        } else {
          cb(null);
        }
      } catch (error) {
        cb(null);
        message.error("Lỗi hệ thống");
      }
    },
  }),
});
