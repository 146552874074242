import { Col, Row } from "antd";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../rematch/store";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation } from "swiper";
const LineUp = () => {
  const event = useSelector((state: RootState) => state.event.event);

  return (
    <Row className="line-up">
      {_.sortBy(event?.lineups, "order")?.map((e: any) => (
        <Col md={12} sm={24}>
          <img src={e?.imgUrl} alt="m" />
        </Col>
      ))}

      {/* <>
        <Swiper
          style={{ width: dimension.screenWidth }}
          slidesPerView={dimension.isMobile ? 1 : 2}
          spaceBetween={0}
          // slidesPerGroup={2}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
        >
          {_.sortBy(event?.lineups, "order")?.map((e: any) => (
            <SwiperSlide>
              <img src={e?.imgUrl} alt="m" />
            </SwiperSlide>
          ))}
        </Swiper>
      </> */}
    </Row>
  );
};

export default LineUp;
