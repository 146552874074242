import BlankLayout from "../layouts/BlankLayout";
import HayFestLayout from "../layouts/HayFestLayout";
import MainLayout from "../layouts/MainLayout";
import ConfirmPayment from "../pages/Checkout";
import NotFound from "../pages/Error/404";
import EventDetail from "../pages/EventDetail";
import HayFest from "../pages/HayFest";
import Booking from "../pages/HayFest/Booking";
import Checkout from "../pages/HayFest/Checkout";
import Home from "../pages/Home";
import ListEvent from "../pages/ListEvent";
import Privacy from "../pages/Policy/Privacy";
import Term from "../pages/Policy/Term";
import { RouteConfig } from "./AppRoute";

export const routes = [
  {
    path: "/",
    component: Home,
    layout: MainLayout,
    exact: true,
  },
  {
    path: "/hayfest",
    component: HayFest,
    layout: HayFestLayout,
    exact: true,
    subRoutes: [
      {
        path: "/hayfest/booking",
        component: Booking,
        layout: HayFestLayout,
        exact: true,
      },
      {
        path: "/hayfest/checkout/:id",
        component: Checkout,
        layout: HayFestLayout,
        exact: true,
      },
    ],
  },
  {
    path: "/events",
    component: ListEvent,
    layout: MainLayout,
    exact: true,
  },
  {
    path: "/event/:id",
    component: EventDetail,
    layout: MainLayout,
    exact: true,
  },
  {
    path: "/checkout/:id",
    component: ConfirmPayment,
    layout: MainLayout,
    exact: true,
  },
  {
    path: "/auth",
    component: Home,
    layout: MainLayout,
    exact: true,
  },
  {
    path: "/privacy",
    layout: BlankLayout,
    component: Privacy,
    exact: true,
  },
  {
    path: "/term",
    layout: BlankLayout,
    component: Term,
    exact: true,
  },
  {
    component: NotFound,
  },
] as Array<RouteConfig>;
