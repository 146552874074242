export enum DiscountType {
  PERCENTAGE = "PERCENTAGE",
  AMOUNT = "AMOUNT",
}
export enum Type {
  VOUCHER = "VOUCHER",
  COUPON = "COUPON",
}

export interface IDiscount {
  id: string;
  code: string;
  amount: number;
  discountType: DiscountType;
  amountDiscount: number;
  percentageDiscount: number;
  type: Type;
  appliedEventId?: string;
  description?: string;
  appliedSeatTypes?: string[];
  startDate?: string;
  endDate?: string;
  createdByUserId: string;
}
