import { Button } from "antd";
import { getSeatProps } from "../helper";
import { BlockProps, CustomRenderRowProps, StageProps } from "../types";

function toRadians(angle: number) {
  return angle * (Math.PI / 180);
}

const Left1Block: BlockProps = {
  name: "BLOCK01",
  seatRows: [
    {
      name: "FF",
      from: 1,
      to: 10,
      color: "#aa4e36",
      seatClass: "STAR",
    },
    {
      name: "EE",
      from: 1,
      to: 9,
      offset: 2,
      color: "#aa4e36",
      seatClass: "STAR",
    },
    {
      name: "DD",
      from: 1,
      to: 9,
      offset: 2,
      color: "#aa4e36",
      seatClass: "STAR",
    },
    {
      name: "CC",
      from: 1,
      to: 9,
      offset: 2,
      seatClass: "MOON",
    },
    {
      name: "BB",
      from: 1,
      to: 9,
      offset: 2,
      seatClass: "MOON",
    },
    {
      name: "AA",
      from: 1,
      to: 10,
      seatClass: "MOON",
    },
  ],
};

const Center1Block: BlockProps = {
  name: "BLOCK02",

  seatRows: [
    {
      name: "FF",
      from: 11,
      to: 30,
      seatClass: "MOON",
    },
    {
      name: "EE",
      from: 10,
      to: 27,
      offset: 2,
      seatClass: "MOON",
    },
    {
      name: "DD",
      from: 10,
      to: 27,
      offset: 2,
      seatClass: "MOON",
    },
    {
      name: "CC",
      from: 10,
      to: 27,
      offset: 2,
      seatClass: "MOON",
    },
    {
      name: "BB",
      from: 10,
      to: 27,
      offset: 2,
      seatClass: "MOON",
    },
    {
      name: "AA",
      from: 11,
      to: 30,
      seatClass: "MOON",
    },
  ],
};

const Right1Block: BlockProps = {
  name: "BLOCK03",

  seatRows: [
    {
      name: "FF",
      from: 31,
      to: 40,
      color: "#aa4e36",
      seatClass: "STAR",
    },
    {
      name: "EE",
      from: 28,
      to: 36,
      color: "#aa4e36",
      seatClass: "STAR",
    },
    {
      name: "DD",
      from: 28,
      to: 36,
      color: "#aa4e36",
      seatClass: "STAR",
    },
    {
      name: "CC",
      from: 28,
      to: 36,
      seatClass: "MOON",
    },
    {
      name: "BB",
      from: 28,
      to: 36,
      seatClass: "MOON",
    },
    {
      name: "AA",
      from: 31,
      to: 40,
      seatClass: "MOON",
    },
  ],
};

const Left2Block: BlockProps = {
  name: "BLOCK04",

  seatRows: [
    {
      name: "C",
      from: 1,
      to: 7,
      color: "#bd7d4c",
      seatClass: "UNIVERSE",
    },
    {
      name: "B",
      from: 1,
      to: 6,
      color: "#bd7d4c",
      offset: 1.5,
      seatClass: "UNIVERSE",
    },
  ],
  options: {
    buttonStyle: {
      marginLeft: 6,
      marginRight: 6,
    },
  },
};

const Center2Block: BlockProps = {
  name: "BLOCK05",

  seatRows: [
    {
      name: "C",
      from: 8,
      to: 20,
      color: "#bd7d4c",
      seatClass: "UNIVERSE",
    },
    {
      name: "B",
      from: 7,
      to: 18,
      color: "#bd7d4c",
      offset: 1.5,
      seatClass: "UNIVERSE",
    },
  ],
  options: {
    buttonStyle: {
      marginLeft: 6,
      marginRight: 6,
    },
  },
};

const Right2Block: BlockProps = {
  name: "BLOCK06",

  seatRows: [
    {
      name: "C",
      from: 21,
      to: 27,
      color: "#bd7d4c",
      seatClass: "UNIVERSE",
    },
    {
      name: "B",
      from: 19,
      to: 24,
      color: "#bd7d4c",
      offset: 1.5,
      seatClass: "UNIVERSE",
    },
  ],
  options: {
    buttonStyle: {
      marginLeft: 6,
      marginRight: 6,
    },
  },
};

const Center3Block: BlockProps = {
  name: "BLOCK07",
  seatRows: [
    {
      name: "SUN",
      from: 7,
      to: 13,
      color: "#aa4e36",
      seatClass: "SUN",
      render: (props: any) => {
        let table = [];
        for (let i = 7; i <= 13; i++) {
          const space = 13 - 7;
          const { isBooked, isUnavailable, backgroundColor, seat } =
            getSeatProps({
              block: Center4Block.name,
              seatRow: Center4Block.seatRows[0].name,
              position: i,
              seatClass: Center4Block.seatRows[0].seatClass,
              priceMap: props.priceMap,
              unavailableSeatIds: props.unavailableSeatIds,
              bookedSeatIds: props.bookedSeatIds,
              selectedSeats: props.selectedSeats,
            });
          const x = -100 * Math.cos(toRadians(180 / (space + 2)) * (i - space));
          const y = -280 * Math.sin(toRadians(180 / (space + 2)) * (i - space));
          table.push(
            <Button
              disabled={isUnavailable || isBooked}
              onClick={() => {
                props.onSeatClick(seat);
              }}
              type="text"
              className="flex-center"
              style={{
                width: 40,
                height: 70,
                flexDirection: "column",
                transform: `translateX(${Math.round(
                  x
                )}px) translateY(${Math.round(y)}px) rotate(${
                  -90 + (180 / space) * (i - space - 1)
                }deg)`,
              }}
            >
              <div className="flex-center">
                <div
                  className="square"
                  style={{
                    backgroundColor,
                  }}
                ></div>
                <div
                  className="square"
                  style={{
                    backgroundColor,
                  }}
                ></div>
              </div>
              <div
                className="flex-center"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "100%",
                  backgroundColor,
                }}
                key={i}
              >
                {i}
              </div>
            </Button>
          );
        }
        return (
          <div className="flex-center" style={{ marginTop: 160 }}>
            {table}
          </div>
        );
      },
    },
  ],
  options: {
    buttonStyle: {
      marginLeft: 6,
      marginRight: 6,
    },
  },
};

const Center4Block: BlockProps = {
  name: "BLOCK08",

  seatRows: [
    {
      name: "SUN",
      from: 1,
      to: 6,
      color: "#d7a578",
      seatClass: "SUN",
      render: (props: CustomRenderRowProps) => {
        let table = [];
        for (let i = 1; i <= 6; i++) {
          const x = -50 * Math.cos(toRadians(180 / 7) * i);
          const y = -150 * Math.sin(toRadians(180 / 7) * i);
          const { isBooked, isUnavailable, backgroundColor, seat } =
            getSeatProps({
              block: Center4Block.name,
              seatRow: Center4Block.seatRows[0].name,
              position: i,
              seatClass: Center4Block.seatRows[0].seatClass,
              priceMap: props.priceMap,
              unavailableSeatIds: props.unavailableSeatIds,
              bookedSeatIds: props.bookedSeatIds,
              selectedSeats: props.selectedSeats,
            });
          table.push(
            <Button
              disabled={isBooked || isUnavailable}
              onClick={() => {
                props.onSeatClick(seat);
              }}
              type="text"
              className="flex-center"
              style={{
                width: 40,
                height: 70,
                flexDirection: "column",
                transform: `translateX(${Math.round(
                  x
                )}px) translateY(${Math.round(y)}px) rotate(${
                  -90 + (180 / 5) * (i - 1)
                }deg)`,
              }}
            >
              <div className="flex-center">
                <div
                  className="square"
                  style={{
                    backgroundColor,
                  }}
                ></div>
                <div
                  className="square"
                  style={{
                    backgroundColor,
                  }}
                ></div>
              </div>

              <div
                className="flex-center"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: "100%",
                  backgroundColor,
                }}
                key={i}
              >
                {i}
              </div>
            </Button>
          );
        }
        return (
          <div className="flex-center" style={{ marginTop: -150 }}>
            {table}
          </div>
        );
      },
    },
  ],
  options: {
    buttonStyle: {
      marginLeft: 6,
      marginRight: 6,
    },
  },
};

const Left5Block = {
  name: "BLOCK09",

  seatRows: [
    {
      name: "GF",
      from: 1,
      to: 5,
      offset: 3,
      style: {},
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(90deg)",
      },
      labelStyle: {
        transform: "rotate(90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GE",
      from: 1,
      to: 7,
      style: {},
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GD",
      from: 1,
      to: 7,
      style: {},
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GC",
      from: 1,
      to: 5,
      style: {},
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GB",
      from: 1,
      to: 5,
      style: {},
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GA",
      from: 1,
      to: 4,
      style: {},
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(90deg)",
      },
      seatClass: "GIFT",
    },
  ],
  options: {
    labelPosition: "start",
    labelStyle: {
      transform: "rotate(90deg)",
    },
  },
};

const Right5Block = {
  name: "BLOCK10",

  seatRows: [
    {
      name: "GD",
      from: 8,
      to: 12,
      color: "#8e7754",
      style: {
        flexDirection: "row-reverse",
      },
      buttonStyle: {
        transform: "rotate(-90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GC",
      from: 6,
      to: 10,
      style: {
        flexDirection: "row-reverse",
      },
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(-90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GB",
      from: 6,
      to: 9,
      style: {
        flexDirection: "row-reverse",
      },
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(-90deg)",
      },
      seatClass: "GIFT",
    },
    {
      name: "GA",
      from: 5,
      to: 7,
      style: {
        flexDirection: "row-reverse",
      },
      color: "#8e7754",
      buttonStyle: {
        transform: "rotate(-90deg)",
      },
      seatClass: "GIFT",
    },
  ],
  options: {
    labelPosition: "start",
    labelStyle: {
      transform: "rotate(-90deg)",
    },
  },
};

export const SaltusStageFormat: StageProps = {
  rows: [
    {
      name: "ROW_1",
      cols: [
        {
          style: {
            transform: "rotate(-40deg)",
            width: 260,
          },
          data: Left1Block,
        },
        {
          style: {
            width: 600,
            marginBottom: 100,
          },
          data: Center1Block,
        },
        {
          style: {
            transform: "rotate(40deg)",
            width: 260,
          },
          data: Right1Block,
        },
      ],
      style: {
        marginBottom: -100,
      },
    },
    {
      name: "ROW_2",
      cols: [
        {
          style: {
            transform: "rotate(-40deg)",
            width: 260,
            marginTop: -80,
          },
          data: Left2Block,
          innerStyle: {
            border: "none",
          },
        },
        {
          style: {
            width: 370,
            marginBottom: 200,
          },
          data: Center2Block,
          innerStyle: {
            border: "none",
          },
        },
        {
          style: {
            transform: "rotate(40deg)",
            width: 260,
            marginTop: -80,
          },
          data: Right2Block,
          innerStyle: {
            border: "none",
          },
        },
      ],
      style: {
        marginBottom: -100,
        marginTop: -80,
      },
    },
    {
      name: "ROW_3",
      cols: [
        {
          data: Center3Block,
          innerStyle: {
            border: "none",
          },
        },
      ],
    },
    {
      name: "ROW_4",
      cols: [
        {
          data: Center4Block,
          innerStyle: {
            border: "none",
          },
        },
      ],
    },
    {
      name: "ROW_5",
      cols: [
        {
          style: {
            transform: "rotate(-90deg)",
            width: 400,
            marginTop: 100,
          },
          data: Left5Block,
          innerStyle: {
            border: "none",
          },
        },
        {
          style: {
            width: 400,
          },
          type: "custom",
          render: () => (
            <div className="main-stage flex-center">
              <b>SÂN KHẤU</b>
            </div>
          ),
        },
        {
          style: {
            transform: "rotate(90deg)",
            width: 400,
            marginTop: 100,
          },
          innerStyle: {
            border: "none",
          },
          data: Right5Block,
        },
      ],
      style: {
        marginTop: -300,
      },
    },
    {
      name: "ROW_6",
      cols: [
        {
          style: {
            marginTop: -50,
          },
          type: "custom",
          render: () => (
            <div className="main-stage-rec flex-center">
              <b>SÂN KHẤU</b>
            </div>
          ),
        },
      ],
    },
  ],
  options: {
    width: 1296,
    height: 860,
  },
};
