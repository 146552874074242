import { DefaultEventsMap } from "@socket.io/component-emitter";

import { io, Socket } from "socket.io-client";
import * as MapHandlers from "../handlers/map-handlers";
import { MapEvent } from "../events/map-event";
import { configs } from "../../services/config";
import { store } from "../../rematch/store";

let instance: Socket<DefaultEventsMap, DefaultEventsMap> | null;

export const initMapSocket = (id: string) => {
  const socket = getMapSocketInstance(id);
  MapHandlers.initMapHandlers(socket);

  socket.on("connect", () => {
    store.dispatch.booking.updateBookingState({ socketId: socket.id });
  });

  socket.on("connect_error", (err: any) => {
    console.log("socker error:", err.message);
  });

  return socket;
};

export const getMapSocketInstance = (id: string) => {
  if (!instance) {
    instance = io(`${configs.socketURL}/event/${id}`, {
      reconnectionDelayMax: 10000,
      auth: {
        token: store.getState().auth.tokens?.access.token,
      },
      query: {
        userId: store.getState().auth.user?.id,
      },
    });
  }
  return instance;
};

export const emitEvent = (id: string, event: string, data: any) => {
  instance?.emit(event, data);
};

export const disconnect = (id: string) => {
  instance?.disconnect();
  instance = null;
};

export const MapEventEmitter = {
  selectSeats: (id: string, seats: any) => {
    emitEvent(id, MapEvent.SELECT_SEATS, {
      seats,
    });
  },
};
