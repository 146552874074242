import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../rematch/store";
import useLoading from "../../../hooks/useLoading";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SocialLogin from "../SocialLogin";
const InApp = require("detect-inapp");

// const debounced = _.debounce((callback) => {
//   return callback();
// }, 500);

const Register = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [emailExisted, setEmailExisted] = useState(false);
  const onFinish = async (values: any) => {
    dispatch.auth.register({
      ...values,
      cb: (res: any) => {
        const params = new URLSearchParams(window.location.search);

        const currentPath = window.location.pathname;
        if (res) {
          params.append("register", "success");
          params.append("email", values.email);

          // dispatch.auth.changeAuthPage("login");
          dispatch.auth.loginSuccess(res);

          navigate(`${currentPath}?${params.toString()}`);
        } else {
          setEmailExisted(true);
          // message.error("Đăng ký thất bại do email đã tồn tại");
        }
      },
    });
  };

  const [form] = Form.useForm();

  const user = useSelector((state: RootState) => state.auth.user);
  const { loading } = useLoading(
    (state: RootState) => state.loading.effects.auth.register
  );

  if (user) {
    dispatch.auth.setAuthModalVisible(false);
    dispatch.auth.changeAuthPage("login");
  }

  const _window: any = window;
  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || _window.opera
  );

  return (
    <Form style={{ marginTop: 32 }} onFinish={onFinish} form={form}>
      <div className="flex-center">
        <h1>Đăng ký</h1>
      </div>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Vui lòng không để trống tên" }]}
      >
        <Input
          size="large"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Họ tên"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Vui lòng không để trống email" },
          { type: "email", message: "Định dạng email không đúng" },
          // {
          //   validator: async (rule, value) => {
          //     return new Promise<any>((resolve, reject) => {
          //       debounced(() =>
          //         dispatch.auth.checkEmail({
          //           email: value,
          //           cb: (res: any) => {
          //             if (res) {
          //               reject("Email đã tồn tại");
          //             } else {
          //               resolve(true);
          //             }
          //           },
          //         })
          //       );
          //     });
          //   },
          // },
        ]}
      >
        <Input
          // onChange={(e) => checkEmailDebounce(e.target.value)}
          onChange={(e) => {
            setEmailExisted(false);
          }}
          size="large"
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 4 }}
        name="password"
        rules={[
          { required: true, message: "Vui lòng không để trống mật khẩu" },
          {
            min: 6,
            message: "Mật khẩu phải có ít nhất 6 ký tự",
          },
          {
            validator: async (rule, value) => {
              if (!value.match(/\d/) || !value.match(/[a-zA-Z]/)) {
                // throw new Error("Mật khẩu phải có ít nhất 1 chữ và một số");
                await Promise.reject(
                  "Mật khẩu phải có ít nhất 1 chữ và một số"
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Mật khẩu"
        />
      </Form.Item>
      {emailExisted && (
        <div style={{ color: "red", fontSize: 12 }} className="mt-1">
          Bạn đã có tài khoản với email này, vui lòng{" "}
          <Button
            style={{ fontSize: 12, padding: 0 }}
            type="link"
            onClick={() => dispatch.auth.changeAuthPage("login")}
          >
            đăng nhập
          </Button>
        </div>
      )}
      <Form.Item>
        <Button
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button mt-2"
          style={{ width: "100%" }}
        >
          Đăng ký
        </Button>
      </Form.Item>
      Đã có tài khoản tại TheBros?{" "}
      <Button
        className="px-0"
        type="link"
        onClick={() => dispatch.auth.changeAuthPage("login")}
      >
        Đăng nhập
      </Button>
      {!inapp.isInApp && (
        <>
          <span className="flex-center mt-1">hoặc</span>
          <div className="mt-1">
            <SocialLogin />
          </div>
        </>
      )}
    </Form>
  );
};

export default Register;
