import React, { useEffect, useState } from "react";
import { Form, Input, Button, Tag } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../rematch/store";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import SocialLogin from "../SocialLogin";
const InApp = require("detect-inapp");

const Login = (props: any) => {
  const dispatch = useDispatch<Dispatch>();

  // get params in url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isRegisterSuccess = params.get("register") === "success";
  const isResetPwdSuccess = params.get("isResetPwdSuccess") === "true";
  const navigate = useNavigate();
  const email = params.get("email");

  const onFinish = (values: any) => {
    dispatch.auth.login(values);
  };

  const user = useSelector((state: RootState) => state.auth.user);
  const authModalVisible = useSelector(
    (state: RootState) => state.auth.authModalVisible
  );

  const { finished, loading, error } = useLoading(
    (state: RootState) => state.loading.effects.auth.login
  );

  const _window: any = window;
  const inapp = new InApp(
    navigator.userAgent || navigator.vendor || _window.opera
  );
  // if (inapp.isInApp) {
  //   return (
  //     <div
  //       className="text flex-center"
  //       style={{ textAlign: "center", width: "100%", flexDirection: "column" }}
  //     >
  //       Bạn đang mở trang web trong trình duyệt của Facebook. Vui lòng sử dụng
  //       trình duyệt Chrome/Safari
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (!authModalVisible) {
      params.delete("register");
      params.delete("isResetPwdSuccess");
      params.delete("email");
      const currentPath = `${window.location.pathname}?${params.toString()}`;
      navigate(currentPath, { replace: true });
    }
    return () => {};
  }, [authModalVisible]);

  if (user) {
    dispatch.auth.setAuthModalVisible(false);
    dispatch.auth.changeAuthPage("login");
  }

  return (
    <Form onFinish={onFinish} style={{ marginTop: 32 }}>
      <div className="flex-center">
        <h1>Đăng nhập</h1>
      </div>
      {isRegisterSuccess && (
        <div className="flex-center mb-1">
          <Tag color="green">Bạn đã đăng ký thành công</Tag>
        </div>
      )}
      {isResetPwdSuccess && (
        <div className="flex-center mb-1">
          <Tag color="green">Bạn đã đặt lại mật khẩu thành công</Tag>
        </div>
      )}
      <Form.Item
        initialValue={email}
        name="email"
        rules={[
          { required: true, message: "Vui lòng không để trống email" },
          { type: "email", message: "Định dạng email không đúng" },
        ]}
      >
        <Input
          size="large"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: 4 }}
        name="password"
        rules={[
          { required: true, message: "Vui lòng không để trống mật khẩu" },
        ]}
      >
        <Input
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Mật khẩu"
        />
      </Form.Item>
      {error && finished && (
        <span style={{ color: "red", fontSize: 12 }}>
          Email hoặc mật khẩu không đúng
        </span>
      )}
      <Form.Item style={{ margin: 0 }}>
        <Button
          className="px-0"
          type="link"
          onClick={() => dispatch.auth.changeAuthPage("forgot-password")}
        >
          Quên mật khẩu
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button mt-2"
          style={{ width: "100%" }}
        >
          Đăng nhập
        </Button>
        {!inapp.isInApp && (
          <>
            <span className="flex-center mt-1">hoặc</span>
            <div className="mt-1">
              <SocialLogin />
            </div>
          </>
        )}
      </Form.Item>
      Chưa có tài khoản?{" "}
      <Button
        className="px-0"
        type="link"
        onClick={() => dispatch.auth.changeAuthPage("register")}
      >
        đăng ký ngay
      </Button>
    </Form>
  );
};

export default Login;
