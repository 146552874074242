/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import "./Booking.scss";
import Stage from "./Stage";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../rematch/store";
import { useParams } from "react-router-dom";
import * as Socket from "../../../sockets/core/event";
import SelectZoneControl from "./SelectZoneControl";
import Header from "../Header";

const Booking = () => {
  const dispatch = useDispatch<Dispatch>();
  const { id } = useParams();
  const bookedSeatIds = useSelector(
    (state: RootState) => state.booking.bookedSeatIds
  );
  const dimension = useSelector((state: RootState) => state.dimension);

  const event = useSelector((state: RootState) => state.event.event);
  const [discount, setDiscount] = useState(null);

  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    dispatch.event.getEventByProgramId({
      programId: "10002",
      cb: () => {
        setLocalLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    Socket.initMapSocket(id!);
    return () => {
      Socket.disconnect(id!);
      dispatch.booking.updateUnavailableSeatIds({
        unavailableSeatIds: [],
        bookingUsers: [],
      });
    };
  }, [id]);

  // if (localLoading) {
  //   return (
  //     <div className="flex-center">
  //       <Spin spinning />
  //     </div>
  //   );
  // }

  return (
    <div
      className="flex-center hayfest-booking"
      style={{
        flexDirection: "column",
        height: "100%",
        minHeight: dimension.screenHeight,
        maxWidth: dimension.screenWidth,
        // overflow: "hidden",
      }}
    >
      <Header />
      <img
        alt="hi"
        src="/hfest/bg_3.jpg"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
        }}
      />

      <Row justify="center" style={{ width: "100%", maxWidth: 960 }}>
        <Col sm={24} md={24}>
          <Stage
            bookedSeatIds={bookedSeatIds}
            event={event}
            discount={discount}
          />
        </Col>
        <Col sm={24} md={24}>
          <SelectZoneControl setDiscount={setDiscount} />
        </Col>
      </Row>
    </div>
  );
};

export default Booking;
