import { Models } from "@rematch/core";
import { auth } from "./auth";
import { event } from "./event";
import { booking } from "./booking";
import { dimension } from "./dimension";
import { program } from "./program";

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  event: typeof event;
  booking: typeof booking;
  dimension: typeof dimension;
  program: typeof program;
}

export const models: RootModel = { auth, event, booking, dimension, program };
