import React, { useEffect } from "react";
import "./Home.scss";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../rematch/store";
import _ from "lodash";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const programs = useSelector((state: RootState) => state.program.programs);

  useEffect(() => {
    dispatch.program.getPrograms({
      visible: true,
    });
    return () => {};
  }, []);
  const ownerPrograms = programs?.filter((e: any) => e.type === "OWNER") || [];
  const partnerPrograms =
    programs?.filter((e: any) => e.type === "PARTNER") || [];
  const maxProgramLength = _.max([
    ownerPrograms.length,
    partnerPrograms.length,
  ]);

  const redirectTo = (url: string) => {
    window.location.href = url;
  };

  return (
    <div className="home" style={{ marginBottom: 32 }}>
      <div className="flex-center" style={{ flexDirection: "column" }}>
        <h1
          className="text flex-center"
          style={{ textAlign: "center", fontSize: 24, fontWeight: "bold" }}
        >
          Xin chào, bạn muốn ghé thăm sự kiện nào?
        </h1>
        <h2 className="text mt-2">Chương trình The Bros tổ chức</h2>
        <Row gutter={[32, 32]} className="mt-1" justify="center">
          {ownerPrograms?.map((program: any) => {
            return (
              <Col
                md={24 / (maxProgramLength || 1)}
                sm={12}
                className="program"
                onClick={() => {
                  program?.customUrl
                    ? redirectTo(program?.customUrl)
                    : navigate(`/events?program=${program?.id}`);
                }}
              >
                <div className="placeholder">
                  <img alt="logo" src={program?.posterImg}></img>
                </div>
                <div className="home-card">
                  <img alt="logo" src={program?.darkLogo}></img>
                  <span>{program?.location}</span>
                  <span>{program?.address}</span>
                </div>
              </Col>
            );
          })}
        </Row>
        {partnerPrograms?.length > 0 && (
          <>
            <h2 className="text mt-3">Chương trình của đối tác</h2>
            <Row gutter={[32, 32]} className="mt-1 w-100" justify="center">
              {programs
                ?.filter((e: any) => e.type === "PARTNER")
                .map((program: any) => {
                  return (
                    <Col
                      md={24 / (maxProgramLength || 1)}
                      sm={12}
                      className="program"
                      onClick={() => {
                        program?.customUrl
                          ? redirectTo(program?.customUrl)
                          : navigate(`/events?program=${program?.id}`);
                      }}
                    >
                      <div className="placeholder">
                        <img alt="logo" src={program?.posterImg}></img>
                      </div>
                      <div className="home-card">
                        <img alt="logo" src={program?.darkLogo}></img>
                        <span>{program?.location}</span>
                        <span>{program?.address}</span>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
