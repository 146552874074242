import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../rematch/store";

const Intro = () => {
  const dimension = useSelector((state: RootState) => state.dimension);
  const titleFontSize = dimension.isMobile ? 27 : 60;
  const subtitleFontSize = dimension.isMobile ? 12 : 16;
  const event = useSelector((state: RootState) => state.event.event);

  return (
    <Row
      className="intro"
      style={{
        width: dimension.screenWidth,
        height: "auto",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Row
        style={{
          width: "100%",
          marginTop: 64,
          marginBottom: 32,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img style={{ height: 48 }} alt="logo" src="/hfest/logo.png" />
        <span className="mt-1">
          <b>GLAMPING</b>{" "}
          <span
            style={{ fontFamily: "Inconsolata", fontSize: 16, fontWeight: 600 }}
          >
            MUSIC FESTIVAL
          </span>
        </span>
      </Row>
      <Row
        justify="center"
        style={{
          width: "100%",
        }}
      >
        <iframe
          style={{
            maxWidth: 720,
            width:
              dimension?.screenWidth > 720
                ? 720
                : dimension?.isMobile
                ? dimension.screenWidth - 24
                : dimension.screenWidth / 2,
            maxHeight: 480,
            height:
              dimension?.screenWidth > 720
                ? 480
                : dimension?.isMobile
                ? ((dimension.screenWidth - 24) * 9) / 16
                : ((dimension.screenWidth / 2) * 9) / 16,
            border: "none",
          }}
          src={`https://www.youtube.com/embed/${event?.introVideoUrl}`}
          title="HayFest"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Row>
      <Row
        justify="center"
        style={{ marginTop: dimension?.isMobile ? 12 : 32, width: "100%" }}
      >
        <div
          style={{
            maxWidth: 570,
            display: "flex",
            flexDirection: "column",
            padding: 6,
          }}
        >
          <span className="info-text">
            <b>Sự kiện âm nhạc quốc tế lớn nhất mùa hè </b>
            này sẽ diễn ra tại Việt Nam vào 6 tháng 8 năm 2022.
          </span>
          <span className="info-text">
            Lần đầu tiên tại Việt Nam,{" "}
            <b>
              các boyband US-UK danh tiếng nhất trong "thời kỳ hoàng kim của các
              boyband" thập niên 80s - 90s
            </b>{" "}
            cùng trình diễn với{" "}
            <b>các nghệ sĩ tài năng của âm nhạc Việt Nam đương đại.</b>
          </span>
          <span className="info-text">
            <b>Glamping Music Festival ĐẦU TIÊN</b> tại Việt Nam với âm nhạc đa
            dạng và các chuỗi hoạt động nghệ thuật đường phố, ẩm thực kết hợp
            glamping dành cho giới trẻ. Hứa hẹn một đại tiệc âm nhạc đa sắc màu
            với âm thanh, ánh sáng, sân khấu và dàn dựng đạt chuẩn quốc tế.
          </span>
        </div>
      </Row>
      <Row
        style={{
          maxWidth: 960,
          marginTop: dimension.isMobile ? 0 : 64,
          marginBottom: dimension.isMobile ? 32 : 64,
        }}
        justify="center"
        gutter={[32, 32]}
      >
        <Col span={7} style={{ maxWidth: 320 }} className="flex-hcenter">
          <div style={{ fontSize: titleFontSize, fontWeight: "bold" }}>18</div>
          <span style={{ textAlign: "center", fontSize: subtitleFontSize }}>
            Nghệ sĩ, nhóm nhạc Việt Nam và quốc tế
          </span>
        </Col>

        <div style={{ width: 1, backgroundColor: "black" }}></div>

        <Col span={7} style={{ maxWidth: 320 }} className="flex-hcenter">
          <div style={{ fontSize: titleFontSize, fontWeight: "bold" }}>14</div>
          <span style={{ textAlign: "center", fontSize: subtitleFontSize }}>
            Giờ trình diễn
          </span>
        </Col>

        <div style={{ width: 1, backgroundColor: "black" }}></div>

        <Col span={7} style={{ maxWidth: 320 }} className="flex-hcenter">
          <div style={{ fontSize: titleFontSize, fontWeight: "bold" }}>05</div>
          <span style={{ textAlign: "center", fontSize: subtitleFontSize }}>
            Khu vực hoạt động cả ngày
          </span>
        </Col>
      </Row>
    </Row>
  );
};

export default Intro;
